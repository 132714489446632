import { calculateAccessTokenExpires } from '../../commons';
import * as RestClient from '../../lib/RestClient';
import * as actionTypes from '../constants/actionTypes';
import authService from '../../auth/authService';
import { requestWrapper } from './helperActions';
import { handleApiError, logout } from './errorActions';

export const refreshAccessToken = (spinner = true) => dispatch => {
  const currentDate = Date.now();
  const accessTokenRequest = accessTokenRequestWrapper(
    authService.refreshAccessToken(),
    currentDate
  )(dispatch);

  return spinner
    ? requestWrapper(accessTokenRequest)(dispatch)
    : accessTokenRequest.catch(error => {
        dispatch(handleApiError(error));
        return error;
      });
};

export const fetchTokensSuccess = (accessToken, accessTokenExpires, refreshToken) => ({
  type: actionTypes.FETCH_TOKENS_SUCCESS,
  accessToken,
  accessTokenExpires,
  refreshToken,
});

export const fetchTokenInfoSuccess = token => ({
  type: actionTypes.FETCH_TOKEN_INFO_SUCCESS,
  token,
});

export const fetchUserProfileSuccess = user => ({
  type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
  user,
});

const fetchUserProfileByAccessToken = accessToken => dispatch => {
  return RestClient.fetchTokenInfo(accessToken)
    .then(response => {
      dispatch(fetchTokenInfoSuccess(response.data));
      return response.data.user_name;
    })
    .then(userId => RestClient.fetchUserProfileById(userId))
    .then(response => {
      dispatch(fetchUserProfileSuccess(response.data));
      return response.data;
    });
};

const accessTokenRequestWrapper = (accessTokenRequest, requestDate) => dispatch => {
  return accessTokenRequest
    .then(response => {
      const accessTokenExpires = calculateAccessTokenExpires(requestDate, response.expiresIn);
      dispatch(fetchTokensSuccess(response.accessToken, accessTokenExpires, response.refreshToken));
      return response.accessToken;
    })
    .then(accessToken => {
      return dispatch(fetchUserProfileByAccessToken(accessToken));
    })
    .catch(error => {
      if (error.response && error.response.status) {
        dispatch(logout());
      }
      /* eslint-disable no-throw-literal */
      throw {
        tokenError: true,
        response: error.response || { status: parseFloat(error.message) },
        ...error,
      };
    });
};

export const fetchTokensAfterAuthorization = () => dispatch => {
  const currentDate = Date.now();
  const accessTokenRequest = accessTokenRequestWrapper(
    authService.completeAuthorizationAndFetchTokens(),
    currentDate
  )(dispatch);
  return requestWrapper(accessTokenRequest)(dispatch);
};
