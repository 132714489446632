import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import authorizationMiddleware from './middleware/authorization';
import { getRefreshTokenFromStorage, setRefreshTokenInStorage } from '../auth/tokenStorage';

// TODO @Mariusz check if composeEnhancers will work if code run outside of browser

/* eslint-disable no-underscore-dangle */
const extCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = typeof extCompose === 'function' ? extCompose({ trace: true }) : compose;

const middleware = applyMiddleware(thunkMiddleware, authorizationMiddleware);
const store = createStore(rootReducer, composeEnhancers(middleware));

store.subscribe(() => {
  const { refreshToken } = store.getState().handleAuthorization;
  if (getRefreshTokenFromStorage() !== refreshToken) {
    setRefreshTokenInStorage(refreshToken);
  }
});

export default store;
