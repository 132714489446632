import * as actionTypes from '../constants/actionTypes';

export const addDays = days => ({
  type: actionTypes.ADD_DAYS,
  days,
});

export const changeDate = date => ({
  type: actionTypes.CHANGE_DATE,
  date,
});
