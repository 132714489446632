import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { createReservation, postReservationSeries } from '../../redux/actions';
import { convertDateToBackendFormat } from '../../commons';
import constants from '../../constants';

class QuickReservation extends Component {
  reserveSpace = async (spaceId, lengthInDays) => {
    const { favorites, login, chosenDate } = this.props;
    const convertedChosenDate = convertDateToBackendFormat(chosenDate);
    const reservation = {
      startTime: favorites.startTime,
      endTime: favorites.endTime,
      date: convertedChosenDate,
      spaceId,
      employee: login,
      loginUser: login,
      guestName: null,
      comment: '',
      confirmed: true,
    };
    if (lengthInDays === 1) {
      return this.props.createReservation(reservation);
    }
    const reservationSeries = [];
    for (let i = 0; i < lengthInDays; i++) {
      const date = moment(chosenDate, constants.DATE_FORMAT_FRONTEND);
      const nextDayDate = moment(date.clone().add(i, 'days'));
      const convertedNextDayDate = convertDateToBackendFormat(nextDayDate);
      reservationSeries.push({
        ...reservation,
        date: convertedNextDayDate,
      });
    }
    return this.props.postReservationSeries(reservationSeries, true);
  };

  handleQuickReservation = async lengthInDays => {
    const { favorites, setMessage } = this.props;
    this.reserveSpace(favorites.spaceId, lengthInDays).then(res => {
      if (res && typeof res.serviceMessage === 'string') {
        setMessage('quickReservation.spaceBlocked');
      }
    });
  };

  render() {
    const { favorites, login, days, children, className } = this.props;
    return favorites && login ? (
      <button type="button" onClick={() => this.handleQuickReservation(days)} className={className}>
        {children}
      </button>
    ) : null;
  }
}

QuickReservation.propTypes = {
  createReservation: PropTypes.func.isRequired,
  postReservationSeries: PropTypes.func.isRequired,
  setMessage: PropTypes.func,
  login: PropTypes.string,
  chosenDate: PropTypes.string.isRequired,
  favorites: PropTypes.shape({
    id: PropTypes.number,
    employee: PropTypes.string,
    endTime: PropTypes.string,
    preferredStartPage: PropTypes.string,
    spaceBackupId: PropTypes.number,
    spaceId: PropTypes.number,
    startTime: PropTypes.string,
  }),
  days: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

QuickReservation.defaultProps = {
  setMessage: () => {},
  favorites: undefined,
  login: '',
  days: undefined,
  children: null,
  className: '',
};

const mapStateToProps = state => ({
  favorites: state.handleFavorites.favorites,
  chosenDate: state.handleDate.chosenDate,
  login: state.handleUser.user.login,
  reservations: state.handleReservations.reservations,
});

const mapDispatchToProps = {
  createReservation,
  postReservationSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickReservation);
