import { combineReducers } from 'redux';
import handleAuthorization from './handleAuthorization';
import handleDate from './handleDate';
import handleError from './handleError';
import handlePlanActions from './handlePlanActions';
import handleReservations from './handleReservations';
import handleSingleReservation from './handleSingleReservation';
import handleSpaces from './handleSpaces';
import handleApplication from './handleApplication';
import handleUser from './handleUser';
import handleFavorites from './handleFavorites';
import handleVersion from './handleVersion';

const rootReducer = combineReducers({
  handleAuthorization,
  handleDate,
  handleError,
  handlePlanActions,
  handleReservations,
  handleSingleReservation,
  handleSpaces,
  handleApplication,
  handleUser,
  handleFavorites,
  handleVersion,
});

export default rootReducer;
