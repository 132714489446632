const constants = {
  PATH: {
    SPACES: '/spaces',
    PLAN_MAP: '/plan/',
    RESERVATIONS: '/reservations',
    RESERVATION: '/reservation/:id?/',
    TOKEN: '/token',
    ABOUT: '/about',
    NOT_FOUND: '/404',
  },
  RESERVATION_URL: '/reservation',
  SPACES_REDIRECT_PATH_PARAM_NAME: 'spacesRedirectPath',
  REFRESH_TOKEN_COOKIE_KEY: 'refresh_token',
  REFRESH_TOKEN_COOKIE_EXPIRES: 30,
  DATE_FORMAT_BACKEND: 'YYYY-MM-DD',
  DATE_FORMAT_FRONTEND: 'DD.MM.YYYY',
  TIME_FORMAT: 'HH:mm:ss',
  TIME_FORMAT_SHORT: 'HH:mm',
  EMPLOYEES_FILTER: {
    departments: ['AKRA'],
    employeeIds: [],
    logins: [],
    userStatusList: ['INTERNAL'],
    former: false,
  },
  MOMENT_LOCALE_DATA: {
    'en-GB': {
      months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split(
        '_'
      ),
      weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    },
    pl: {
      months: 'Styczeń_Luty_Marzec_Kwiecień_Maj_Czerwiec_Lipiec_Sierpień_Wrzesień_Październik_Listopad_Grudzień'.split(
        '_'
      ),
      weekdaysMin: ['Ni', 'Po', 'Wt', 'Śr', 'Cz', 'Pi', 'So'],
    },
    de: {
      months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split(
        '_'
      ),
      weekdaysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    },
  },
  RESERVATION_MAX_DAYS: 60,
};

export const SPACE_STATE = {
  FREE: 'FREE',
  BUSY: 'BUSY',
  LIMITED: 'LIMITED',
};

export default constants;
