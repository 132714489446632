import { AppAuthError, BaseTokenRequestHandler, TokenError, TokenResponse } from '@openid/appauth';
import { params, isBlank } from '../commons';

class SpacesTokenRequestHandler extends BaseTokenRequestHandler {
  getBasicAuthorization = () => {
    return btoa(`${params.oauth2ClientId()}:${params.oauth2ClientSecret()}`);
  };

  performTokenRequest = (configuration, request) => {
    if (isBlank(params.oauth2ClientId()) || isBlank(params.oauth2ClientSecret())) {
      throw new Error(
        'Could not perform token request because of missing client id or client secret'
      );
    }

    const tokenResponse = this.requestor.xhr({
      url: configuration.tokenEndpoint,
      method: 'POST',
      dataType: 'json',
      headers: {
        Authorization: `Basic ${this.getBasicAuthorization()}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: this.utils.stringify(request.toStringMap()),
    });

    return tokenResponse.then(response => {
      if (!response.error) {
        return new TokenResponse(response);
      }
      return Promise.reject(new AppAuthError(response.error, new TokenError(response)));
    });
  };
}

export default SpacesTokenRequestHandler;
