import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import '../../css/TimeRangeSlider.css';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import constants from '../../constants';
import { isGivenDateToday } from '../../commons';

export const valueFromTime = time => {
  const m = moment(time, 'HH:mm');
  const h = m.get('hour');
  const v = 2 * h + Math.ceil(m.get('minute') / 30);
  return v;
};

export const timeFromValue = (value, withoutSeconds = false) => {
  const timeFormat = withoutSeconds ? 'HH:mm' : constants.TIME_FORMAT;
  const h = Math.floor(value / 2);
  const m = (value % 2) * 30;
  const time = moment(
    moment()
      .set('hour', h)
      .set('minute', m)
      .set('second', 0)
  );
  if (value === 48) time.subtract(1, 'minute');
  return time.format(timeFormat);
};

class TimeRangeSlider extends Component {
  constructor(props) {
    super(props);
    const [newStart, newEnd] = this.calculateNewReservationTime();
    const start = props.start ? valueFromTime(props.start) : newStart;
    const end = props.end ? valueFromTime(props.end) : newEnd;
    this.state = {
      start,
      end,
      prevStart: null,
      prevEnd: null,
    };
  }

  componentDidMount() {
    const { start, end } = this.state;
    const { onRangeChange } = this.props;
    onRangeChange(timeFromValue(start), timeFromValue(end));
  }

  componentDidUpdate(prevProps, prevState) {
    const { start, end, date } = this.props;
    if (start && end && (start !== prevProps.start || end !== prevProps.end)) {
      this.onSliderChange([valueFromTime(start), valueFromTime(end)]);
    }
    if (date !== prevProps.date) {
      this.handleDateChange();
    }
  }

  calculateNewReservationTime = () => {
    const { date } = this.props;
    let start;
    let end;

    if (isGivenDateToday(date)) {
      const currentHours = moment().hours();

      start =
        currentHours === 23
          ? moment()
              .hours(0)
              .minutes(0)
              .seconds(0)
          : moment(moment(), constants.TIME_FORMAT);
      end =
        currentHours > 19 && currentHours < 23
          ? moment(start)
              .hours(23)
              .minutes(59)
              .seconds(0)
          : moment(start)
              .hours(20)
              .minutes(0)
              .seconds(0);

      if (currentHours === 23) {
        this.props.addDays(1);
      }
    } else {
      start = moment(date, constants.DATE_FORMAT_FRONTEND)
        .hours(0)
        .minutes(0)
        .seconds(0);
      end = moment(date, constants.DATE_FORMAT_FRONTEND)
        .hours(23)
        .minutes(59)
        .seconds(0);
    }
    return [valueFromTime(start), valueFromTime(end)];
  };

  handleDateChange = () => {
    const { isForUpdate } = this.props;
    if (!isForUpdate) {
      const [start, end] = this.calculateNewReservationTime();
      this.setState({
        start,
        end,
      });
    }
  };

  changeRangeToWholeDay = () => {
    this.setState(prevState => ({
      start: 0,
      end: 48,
      prevStart: prevState.start,
      prevEnd: prevState.end,
    }));
    this.props.onRangeChange(timeFromValue(0), timeFromValue(48));
  };

  restorePreviousRange = () => {
    const start = this.state.prevStart;
    const end = this.state.prevEnd;

    this.setState(prevState => ({
      start: prevState.prevStart,
      end: prevState.prevEnd,
      prevStart: null,
      prevEnd: null,
    }));

    this.props.onRangeChange(timeFromValue(start), timeFromValue(end));
  };

  onCheckboxCheck = () => {
    this.props.handleCheckboxCheck();
    if (!this.props.seriesModeEnabled) {
      this.changeRangeToWholeDay();
    } else {
      this.restorePreviousRange();
    }
  };

  getTooltipContainer = index => {
    if (this.props.customTooltipContainer) {
      return document.querySelector(`.rc-slider-handle-${index + 1}`);
    }
    return document.querySelector('body');
  };

  handle = props => {
    const { value, dragging, index, customTooltipContainer, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={timeFromValue(value, true)}
        visible
        defaultVisible
        placement="top"
        key={index}
        getTooltipContainer={() => this.getTooltipContainer(index)}
      >
        <Slider.Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  onSliderChange = value => {
    this.setState({
      start: value[0],
      end: value[1],
    });
    this.props.onRangeChange(timeFromValue(value[0]), timeFromValue(value[1]));
    this.props.setErrorMessage('');
  };

  render = () => {
    const handleStyle = {
      backgroundColor: '#CC1C29',
      border: 0,
      width: '4vw',
      maxWidth: '30px',
      height: '4vw',
      maxHeight: '30px',
    };
    const { isAnyReservation, seriesModeEnabled, isForUpdate, translate } = this.props;
    return (
      <div className="TimeRangeSlider">
        <div className="TimeRangeSlider-slider">
          <span className="TimeRangeSlider-sliderRangeLabel TimeRangeSlider-leftSliderRangeLabel">
            {translate('reservation.from')}
          </span>
          <Slider.Range
            min={0}
            max={48}
            value={[this.state.start, this.state.end]}
            handleStyle={[handleStyle, handleStyle]}
            trackStyle={[{ backgroundColor: '#CC1C29', height: '0.5vw', maxHeight: '4px' }]}
            railStyle={{ backgroundColor: '#eeeeee', height: '0.5vw', maxHeight: '4px' }}
            handle={this.handle}
            onChange={this.onSliderChange}
            disabled={seriesModeEnabled}
            allowCross={seriesModeEnabled}
            pushable={1}
          />
          <span className="TimeRangeSlider-sliderRangeLabel TimeRangeSlider-rightSliderRangeLabel">
            {translate('reservation.to')}
          </span>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon className="TimeRangeSlider-checkboxSvg" />}
              checkedIcon={<CheckBoxIcon className="TimeRangeSlider-checkboxSvg" />}
              checked={seriesModeEnabled && !isAnyReservation}
              onChange={this.onCheckboxCheck}
              classes={{
                root: 'TimeRangeSlider-checkbox',
                checked: 'TimeRangeSlider-checkbox--checked',
              }}
              disabled={isForUpdate}
            />
          }
          label={
            <span
              className={classnames('TimeRangeSlider-sliderRangeLabel', {
                'TimeRangeSlider-sliderRangeLabel--disabled': isForUpdate,
              })}
            >
              {translate('reservation.series')}
              {isForUpdate && translate('reservation.optionUnavailable')}
            </span>
          }
        />
      </div>
    );
  };
}

TimeRangeSlider.propTypes = {
  /** Reservation start time */
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Reservation end time */
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Handler for adjusting the date */
  onRangeChange: PropTypes.func.isRequired,
  /** Adding one day when reservation made after 11 p.m. */
  addDays: PropTypes.func.isRequired,
  /** Displays error message when space is booked within given hours' range */
  setErrorMessage: PropTypes.func,
  /** Prop used by rc-tooltip lib component. Here it's a handle component -
   * when it's none, the tooltips are attached to body DOM element with React's portal feature and
   * it takes some time to update their new position while resizing window etc. */
  customTooltipContainer: PropTypes.bool,
  /** Handler for checkbox, toggling series mode */
  handleCheckboxCheck: PropTypes.func,
  /** Checks if space is completely empty. When not, series mode toggle checkbox is disabled (reservation not possible for whole day.) */
  isAnyReservation: PropTypes.bool,
  /** When true, it blocks the slider and sets timespan to whole day */
  seriesModeEnabled: PropTypes.bool,
  /** When in update mode, series mode toggle checkbox is disabled. It's only possible to make a reservation series with fresh reservation. */
  isForUpdate: PropTypes.bool,
  date: PropTypes.string,
  translate: PropTypes.func,
};

TimeRangeSlider.defaultProps = {
  start: undefined,
  end: undefined,
  setErrorMessage: () => {},
  customTooltipContainer: false,
  handleCheckboxCheck: () => {},
  isAnyReservation: false,
  seriesModeEnabled: false,
  isForUpdate: false,
  translate: () => {},
  date: null,
};

export default TimeRangeSlider;
