import moment from 'moment';
import constants from './constants';

export const isBlank = text => !text || text === '';
export const isNotBlank = text => !isBlank(text);

export const getRedirectRoute = () =>
  window.localStorage.getItem(constants.SPACES_REDIRECT_PATH_PARAM_NAME) || constants.PATH.SPACES;
export const isGivenDateToday = date => {
  const momentDate = moment(date, constants.DATE_FORMAT_FRONTEND);
  return moment().isSame(momentDate, 'day');
};

export const calculateAccessTokenExpires = (currentDate, expiresIn) =>
  currentDate + expiresIn * 1000 - 10000;

export const generateUuid = (prefix = 'spaces') => {
  const randomNumber1 = Math.floor(Math.random() * 100000);
  const randomNumber2 = Math.floor(Math.random() * 100000);
  return `${prefix}-${randomNumber1}-${randomNumber2}`;
};

export const convertDateToBackendFormat = date =>
  moment(date, constants.DATE_FORMAT_FRONTEND).format(constants.DATE_FORMAT_BACKEND);

export const params = {
  oauth2ClientId: () => process.env.REACT_APP_OAUTH2_CLIENT_ID,
  oauth2ClientSecret: () => process.env.REACT_APP_OAUTH2_CLIENT_SECRET,
  oauth2Scopes: () => process.env.REACT_APP_OAUTH2_SCOPES,
  oauth2AuthorizeUrl: () => `${process.env.REACT_APP_OAUTH2_URL}/authorize`,
  oauth2TokenUrl: () => `${process.env.REACT_APP_OAUTH2_URL}/token`,
  oauth2TokenInfoUrl: () => `${process.env.REACT_APP_OAUTH2_URL}/token_info`,
  oauth2RedirectUrl: () => process.env.REACT_APP_OAUTH2_REDIRECT_URL,
  getUserInfoByIdUrl: userId => `${process.env.REACT_APP_USERS_API_URL}/users/${userId}`,
  getUserInfoByLoginUrl: login => `${process.env.REACT_APP_USERS_API_URL}/users/login/${login}`,
  getSpacesUrl: date => {
    const formattedDate = date ? `/${convertDateToBackendFormat(date)}` : '';
    return `${process.env.REACT_APP_SPACES_API_URL}/spaces${formattedDate}`;
  },
  getReservationsUrl: date => {
    const formattedDate = date ? `/${convertDateToBackendFormat(date)}` : '';
    return `${process.env.REACT_APP_SPACES_API_URL}/reservations${formattedDate}`;
  },
  searchReservationsUrl: () => `${process.env.REACT_APP_SPACES_API_URL}/reservations/search`,
  postReservationUrl: () => `${process.env.REACT_APP_SPACES_API_URL}/reservations`,
  postQuickReservationSeries: () =>
    `${process.env.REACT_APP_SPACES_API_URL}/reservations/bulk/quick`,
  postReservationSeries: () => `${process.env.REACT_APP_SPACES_API_URL}/reservations/bulk`,
  getReservationByIdUrl: id => `${process.env.REACT_APP_SPACES_API_URL}/reservations/${id}`,
  deleteReservationUrl: reservationId =>
    `${process.env.REACT_APP_SPACES_API_URL}/reservations/${reservationId}`,
  putReservationUrl: reservationId =>
    `${process.env.REACT_APP_SPACES_API_URL}/reservations/${reservationId}`,
  getPlansUrl: () => `${process.env.REACT_APP_SPACES_API_URL}/plans`,
  getPlanImageUrl: planId => `${process.env.REACT_APP_SPACES_API_URL}/plans/${planId}/image`,
  fetchFilteredUsers: () => `${process.env.REACT_APP_USERS_API_URL}/users/search`,
  fetchFavoritesByLogin: login =>
    `${process.env.REACT_APP_SPACES_API_URL}/favorites/login/${login}`,
  createFavorites: () => `${process.env.REACT_APP_SPACES_API_URL}/favorites`,
  deleteFavorites: favoritesId =>
    `${process.env.REACT_APP_SPACES_API_URL}/favorites/${favoritesId}`,
  updateFavorites: favoritesId =>
    `${process.env.REACT_APP_SPACES_API_URL}/favorites/${favoritesId}`,
  getVersion: () => `${process.env.REACT_APP_SPACES_API_URL}/version`,
};

export const resolveSpaceColorClass = spaceState => {
  return spaceState ? `${spaceState.toLowerCase()}Space` : 'freeSpace';
};

export const iPhonePopUpCookieProps = () => ({
  name: 'iPhonePopUpRecentlyClosed',
  lifetime: 7,
});

export const languageCookieName = 'lang';
export const environment = process.env.NODE_ENV;
