import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { changeDate, addDays, setSeriesStartDay, setSeriesEndDay } from '../../redux/actions';
import { isGivenDateToday } from '../../commons';
import constants from '../../constants';
import '../../css/ReservationDayPicker.css';
import Chevron from './Chevron';

const getTodaysDay = () => moment().format(constants.DATE_FORMAT_FRONTEND);

class ReservationDayPicker extends Component {
  componentDidUpdate = prevProps => {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      const { months, weekdaysMin } = constants.MOMENT_LOCALE_DATA[this.props.i18n.language];
      moment.updateLocale(this.props.i18n.language, { months, weekdaysMin });
    }
  };

  convertDateToFrontendFormat = date => moment(date).format(constants.DATE_FORMAT_FRONTEND);

  convertDateToMomentFormat = date => moment(date, constants.DATE_FORMAT_FRONTEND);

  convertDateToPickerFormat = date => this.convertDateToMomentFormat(date).toDate();

  is30DaysFromToday = momentDate =>
    momentDate.isSame(moment().add(constants.RESERVATION_MAX_DAYS, 'days'), 'day');

  addOneDay = date =>
    this.convertDateToFrontendFormat(this.convertDateToMomentFormat(date).add(1, 'days'));

  subtractOneDay = date =>
    this.convertDateToFrontendFormat(this.convertDateToMomentFormat(date).subtract(1, 'days'));

  getVariantValues = () => {
    const { chosenDate, seriesModeEnabled, seriesStartDay, seriesEndDay, isAdmin } = this.props;
    const maxDateValue = isAdmin
      ? null
      : this.convertDateToPickerFormat(moment().add(constants.RESERVATION_MAX_DAYS, 'days'));
    const momentSeriesStartDay = this.convertDateToMomentFormat(seriesStartDay);
    const momentSeriesEndDay = this.convertDateToMomentFormat(seriesEndDay);
    const isButtonDisabledRegardingUserRights = dateToCheck =>
      isAdmin ? false : this.is30DaysFromToday(this.convertDateToMomentFormat(dateToCheck));
    return {
      chosenDate: {
        dateValue: this.convertDateToPickerFormat(chosenDate),
        handleDateChange: value => this.props.changeDate(this.convertDateToFrontendFormat(value)),
        minDateValue: moment().toDate(),
        maxDateValue,
        plusOneDay: () => this.props.addDays(1),
        minusOneDay: () => this.props.addDays(-1),
        disablePlusOneDayButton:
          isButtonDisabledRegardingUserRights(chosenDate) || seriesModeEnabled,
        disableMinusOneDayButton:
          this.convertDateToMomentFormat(chosenDate).isSame(moment(), 'days') || seriesModeEnabled,
        disableInputs: seriesModeEnabled,
      },
      seriesStart: {
        dateValue: this.convertDateToPickerFormat(seriesStartDay || chosenDate),
        handleDateChange: value => {
          this.props.setSeriesStartDay(this.convertDateToFrontendFormat(value));
          if (this.convertDateToMomentFormat(value).isAfter(momentSeriesEndDay, 'days')) {
            this.props.setSeriesEndDay(this.convertDateToFrontendFormat(value));
          }
        },
        minDateValue: moment().toDate(),
        maxDateValue,
        plusOneDay: () => {
          this.props.setSeriesStartDay(this.addOneDay(seriesStartDay));
          if (seriesStartDay === seriesEndDay) {
            this.props.setSeriesEndDay(this.addOneDay(seriesEndDay));
          }
        },
        minusOneDay: () => this.props.setSeriesStartDay(this.subtractOneDay(seriesStartDay)),
        disablePlusOneDayButton: isButtonDisabledRegardingUserRights(seriesStartDay),
        disableMinusOneDayButton: this.convertDateToMomentFormat(seriesStartDay).isSame(
          moment(),
          'days'
        ),
      },
      seriesEnd: {
        dateValue: this.convertDateToPickerFormat(seriesEndDay),
        handleDateChange: value => {
          this.props.setSeriesEndDay(this.convertDateToFrontendFormat(value));
          if (this.convertDateToMomentFormat(value).isBefore(momentSeriesStartDay, 'days')) {
            this.props.setSeriesStartDay(this.convertDateToFrontendFormat(value));
          }
        },
        minDateValue: this.convertDateToPickerFormat(seriesStartDay),
        maxDateValue,
        plusOneDay: () => this.props.setSeriesEndDay(this.addOneDay(seriesEndDay)),
        minusOneDay: () => {
          this.props.setSeriesEndDay(this.subtractOneDay(seriesEndDay));
          if (seriesStartDay === seriesEndDay) {
            this.props.setSeriesStartDay(this.subtractOneDay(seriesStartDay));
          }
        },
        disablePlusOneDayButton: isButtonDisabledRegardingUserRights(seriesEndDay),
        disableMinusOneDayButton: this.convertDateToMomentFormat(seriesEndDay).isSame(
          moment(),
          'days'
        ),
      },
    };
  };

  render() {
    const { chosenDate, seriesModeEnabled, styleVariant, valueVariant, i18n } = this.props;
    const variantToValues = this.getVariantValues()[valueVariant];
    const todaysDay = getTodaysDay();
    const isToday = isGivenDateToday(chosenDate);

    return (
      <div
        className={classnames('ReservationDayPicker', [`ReservationDayPicker--${styleVariant}`], {
          'ReservationDayPicker--seriesModeEnabled': seriesModeEnabled,
        })}
      >
        <button
          type="button"
          className="ReservationDayPicker-button"
          data-test={`${valueVariant}-backToToday`}
          onClick={() => this.props.changeDate(todaysDay)}
          disabled={!(valueVariant === 'chosenDate' && !isToday && !seriesModeEnabled)}
        >
          <Chevron className="ReservationDayPicker-chevron" />
          <Chevron className="ReservationDayPicker-chevron" />
        </button>
        <button
          type="button"
          className="ReservationDayPicker-button"
          data-test={`${valueVariant}-minusOneDay`}
          onClick={variantToValues.minusOneDay}
          disabled={variantToValues.disableMinusOneDayButton}
        >
          <Chevron className="ReservationDayPicker-chevron" />
        </button>
        <div className="react-date-picker__inputGroup__input ReservationDayPicker__dayLetter">
          {moment(variantToValues.dateValue).format('dd')}
        </div>
        <DatePicker
          format="d-M-yy"
          clearIcon={null}
          calendarIcon={null}
          locale={i18n.language}
          value={variantToValues.dateValue}
          onChange={variantToValues.handleDateChange}
          minDetail="month"
          minDate={variantToValues.minDateValue}
          maxDate={variantToValues.maxDateValue}
          disabled={!!variantToValues.disableInputs}
          onContextMenu={e => e.preventDefault()}
        />
        <button
          type="button"
          className="ReservationDayPicker-button"
          data-test={`${valueVariant}-plusOneDay`}
          onClick={variantToValues.plusOneDay}
          disabled={variantToValues.disablePlusOneDayButton}
        >
          <Chevron orientation="right" className="ReservationDayPicker-chevron" />
        </button>
      </div>
    );
  }
}

ReservationDayPicker.propTypes = {
  addDays: PropTypes.func,
  changeDate: PropTypes.func,
  chosenDate: PropTypes.string,
  seriesStartDay: PropTypes.string,
  seriesEndDay: PropTypes.string,
  seriesModeEnabled: PropTypes.bool,
  setSeriesStartDay: PropTypes.func.isRequired,
  setSeriesEndDay: PropTypes.func.isRequired,
  styleVariant: PropTypes.string,
  valueVariant: PropTypes.string,
  isAdmin: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  i18n: PropTypes.object.isRequired,
};

ReservationDayPicker.defaultProps = {
  addDays: () => {},
  changeDate: () => {},
  chosenDate: '',
  seriesStartDay: null,
  seriesEndDay: null,
  seriesModeEnabled: false,
  styleVariant: 'inAppBar',
  valueVariant: 'chosenDate',
  isAdmin: false,
};

const mapStateToProps = state => ({
  chosenDate: state.handleDate.chosenDate,
  isAnyReservation: state.handleSingleReservation.existingSpaceReservations.length > 0,
  seriesModeEnabled: state.handleSingleReservation.seriesModeEnabled,
  seriesStartDay: state.handleSingleReservation.seriesStartDay,
  seriesEndDay: state.handleSingleReservation.seriesEndDay,
});

export default connect(mapStateToProps, {
  changeDate,
  addDays,
  setSeriesStartDay,
  setSeriesEndDay,
})(withTranslation()(ReservationDayPicker));
