import * as actionTypes from '../constants/actionTypes';

const initialState = {
  user: {
    tokenId: null,
    userName: null,
    clientId: null,
    lifeTime: null,
    scope: [],
    login: null,
    firstName: null,
    lastName: null,
  },
};

const handleUser = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOKEN_INFO_SUCCESS:
      /* eslint-disable */
      const { tokenId, user_name, clientId, scope, lifeTime } = action.token;
      return {
        ...state,
        user: { ...state.user, tokenId, userName: user_name, clientId, lifeTime, scope },
      };
    case actionTypes.FETCH_USER_PROFILE_SUCCESS:
      return { ...state, user: { ...state.user, ...action.user } };
    case actionTypes.LOG_OUT:
      return { ...state, user: initialState.user };
    default:
      return state;
  }
};

export default handleUser;
