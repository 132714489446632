import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../shared/Button';
import '../../css/GuestEntry.css';

const GuestEntry = ({
  onSelection,
  isSelected,
  name,
  onNameChange,
  setErrorMessage,
  translate,
}) => (
  <div className="GuestEntry">
    <div className="GuestEntry-toggleUser">
      {translate('reservation.guest')}
      <Button
        active={isSelected}
        handleClick={() => {
          if (!isSelected) {
            onSelection(true);
          }
        }}
      >
        {translate('reservation.yes')}
      </Button>
      <Button
        active={!isSelected}
        handleClick={() => {
          if (isSelected) {
            setErrorMessage('');
            onSelection(false);
          }
        }}
      >
        {translate('reservation.no')}
      </Button>
    </div>
    <input
      type="text"
      placeholder={translate(isSelected ? 'reservation.guestName' : 'reservation.guestUnavailable')}
      className={classnames('GuestEntry-input', { 'GuestEntry-input--active': isSelected })}
      readOnly={!isSelected}
      value={name}
      onChange={evt => {
        setErrorMessage('');
        onNameChange(evt.target.value);
      }}
    />
  </div>
);

GuestEntry.propTypes = {
  onSelection: PropTypes.func,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  onNameChange: PropTypes.func,
  /** Sets error message when user try to reserve space for guest with empty input. Resets when guest is toggled. */
  setErrorMessage: PropTypes.func,
  translate: PropTypes.func,
};

GuestEntry.defaultProps = {
  onSelection: () => {},
  name: '',
  isSelected: false,
  onNameChange: () => {},
  setErrorMessage: () => {},
  translate: () => {},
};

export default GuestEntry;
