import * as actionTypes from '../constants/actionTypes';

export const initialState = {
  id: null,
  seriesModeEnabled: false,
  seriesStartDay: null,
  seriesEndDay: null,
  spaceId: null,
  spaceName: null,
  startTime: null,
  endTime: null,
  guest: {
    selected: false,
    name: '',
  },
  loginUser: null,
  employee: null,
  confirmed: false,
  existingSpaceReservations: [],
};

const handleSingleReservation = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NEW_RESERVATION:
      return { ...state, spaceId: action.spaceId, spaceName: action.spaceName };
    case actionTypes.START_RESERVATION_EDIT:
      /* eslint-disable no-case-declarations */
      const {
        id,
        spaceId,
        spaceName,
        loginUser,
        employee,
        confirmed,
        startTime,
        endTime,
      } = action.reservation;
      return {
        ...state,
        id,
        spaceId,
        spaceName,
        loginUser,
        employee,
        confirmed,
        startTime,
        endTime,
        guest: { selected: !!action.reservation.guestName, name: action.reservation.guestName },
      };
    case actionTypes.CANCEL_NEW_RESERVATION:
    case actionTypes.RESERVATION_POST_SUCCESS:
    case actionTypes.RESERVATION_SERIES_POST_SUCCESS:
    case actionTypes.RESERVATION_PUT_SUCCESS:
      return initialState;
    case actionTypes.SET_GUEST_NAME:
      return { ...state, guest: { ...state.guest, name: action.guestName } };
    case actionTypes.TOGGLE_GUEST_SELECTION:
      return { ...state, guest: { ...state.guest, name: '', selected: !state.guest.selected } };
    case actionTypes.TOGGLE_SERIES_MODE:
      return {
        ...state,
        seriesModeEnabled: !state.seriesModeEnabled,
        seriesStartDay: action.seriesDay,
        seriesEndDay: action.seriesDay,
      };
    case actionTypes.SET_SERIES_END_DAY:
      return { ...state, seriesEndDay: action.endDay };
    case actionTypes.SET_SERIES_START_DAY:
      return { ...state, seriesStartDay: action.startDay };
    case actionTypes.CHANGE_RESERVATION_RANGE:
      return { ...state, startTime: action.startTime, endTime: action.endTime };
    case actionTypes.FETCH_EXISTING_SPACE_RESERVATIONS_SUCCESS:
      return { ...state, existingSpaceReservations: action.existingSpaceReservations };
    default:
      return state;
  }
};

export default handleSingleReservation;
