export const LOG_OUT = 'LOG_OUT';
export const CHANGE_ONLINE_STATUS = 'CHANGE_ONLINE_STATUS';
export const FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS';
export const ADD_DAYS = 'ADD_DAYS';
export const CHANGE_DATE = 'CHANGE_DATE';
export const API_ERROR = 'API_ERROR';
export const RESET_API_ERROR = 'RESET_API_ERROR';
export const PLAN_LIST_GET_SUCCESS = 'PLAN_LIST_GET_SUCCESS';
export const PLAN_GET_SUCCESS = 'PLAN_GET_SUCCESS';
export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';
export const RESERVATIONS_GET_SUCCESS = 'RESERVATIONS_GET_SUCCESS';
export const RESERVATION_DELETE_SUCCESS = 'RESERVATION_DELETE_SUCCESS';
export const ADD_NEW_RESERVATION = 'ADD_NEW_RESERVATION';
export const START_RESERVATION_EDIT = 'START_RESERVATION_EDIT';
export const CANCEL_NEW_RESERVATION = 'CANCEL_NEW_RESERVATION';
export const TOGGLE_SERIES_MODE = 'TOGGLE_SERIES_MODE';
export const SET_SERIES_START_DAY = 'SET_SERIES_START_DAY';
export const SET_SERIES_END_DAY = 'SET_SERIES_END_DAY';
export const SET_GUEST_NAME = 'SET_GUEST_NAME';
export const TOGGLE_GUEST_SELECTION = 'TOGGLE_GUEST_SELECTION';
export const CHANGE_RESERVATION_RANGE = 'CHANGE_RESERVATION_RANGE';
export const RESERVATION_POST_SUCCESS = 'RESERVATION_POST_SUCCESS';
export const RESERVATION_SERIES_POST_SUCCESS = 'RESERVATION_SERIES_POST_SUCCESS';
export const RESERVATION_PUT_SUCCESS = 'RESERVATION_PUT_SUCCESS';
export const FETCH_EXISTING_SPACE_RESERVATIONS_SUCCESS =
  'FETCH_EXISTING_SPACE_RESERVATIONS_SUCCESS';
export const SPACES_GET_SUCCESS = 'SPACES_GET_SUCCESS';
export const HANDLE_GLOBAL_SPINNER = 'HANDLE_GLOBAL_SPINNER';
export const FETCH_TOKEN_INFO_SUCCESS = 'FETCH_TOKEN_INFO_SUCCESS';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const SET_BACKEND_VERSION = 'SET_BACKEND_VERSION';
export const SET_FRONTEND_VERSION = 'SET_FRONTEND_VERSION';
