import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRedirectRoute } from '../../commons';
import { fetchTokensAfterAuthorization } from '../../redux/actions';

class Token extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizationFinished: false,
    };
  }

  componentDidMount = async () => {
    await this.props.fetchTokensAfterAuthorization().then(() => {
      this.setState({
        authorizationFinished: true,
      });
    });
  };

  render = () => {
    const { authorizationFinished } = this.state;
    return authorizationFinished && <Redirect to={getRedirectRoute()} />;
  };
}

Token.propTypes = {
  fetchTokensAfterAuthorization: PropTypes.func.isRequired,
};

export default connect(null, {
  fetchTokensAfterAuthorization,
})(Token);
