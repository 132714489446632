import axios from 'axios';
import { params } from '../commons';
import store from '../redux/store';

const getAccessToken = () => store.getState().handleAuthorization.accessToken;

const getBasicAuthorization = () => {
  return btoa(`${params.oauth2ClientId()}:${params.oauth2ClientSecret()}`);
};

export const fetchPlan = id =>
  axios({
    method: 'get',
    url: params.getPlanImageUrl(id),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const fetchPlanList = () =>
  axios({
    method: 'get',
    url: params.getPlansUrl(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const fetchReservations = forDate =>
  axios({
    method: 'get',
    url: params.getReservationsUrl(forDate),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const fetchReservationById = id =>
  axios({
    method: 'get',
    url: params.getReservationByIdUrl(id),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const searchReservations = filters =>
  axios({
    method: 'post',
    url: params.searchReservationsUrl(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: filters,
  });

export const deleteReservation = reservationId =>
  axios({
    method: 'delete',
    url: params.deleteReservationUrl(reservationId),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

export const createReservation = reservation =>
  axios({
    method: 'post',
    url: params.postReservationUrl(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: reservation,
  });

export const postReservationSeries = (reservationSeries, isQuick = false) =>
  axios({
    method: 'post',
    url: isQuick ? params.postQuickReservationSeries() : params.postReservationSeries(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: reservationSeries,
  });

export const fetchSpaces = forDate =>
  axios({
    method: 'get',
    url: params.getSpacesUrl(forDate),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const updateReservation = (reservation, id) =>
  axios({
    method: 'put',
    url: params.putReservationUrl(id),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: reservation,
  });

export const fetchTokenInfo = () =>
  axios({
    method: 'post',
    url: params.oauth2TokenInfoUrl(),
    data: `token=${getAccessToken()}`,
    headers: {
      Authorization: `Basic ${getBasicAuthorization()}`,
    },
  });

export const fetchUserProfileById = userId =>
  axios({
    method: 'get',
    url: params.getUserInfoByIdUrl(userId),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const fetchUserProfileByLogin = login =>
  axios({
    method: 'get',
    url: params.getUserInfoByLoginUrl(login),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

export const fetchFilteredUsers = filters =>
  axios({
    method: 'post',
    url: params.fetchFilteredUsers(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: filters,
  });

export const createFavorites = favorites =>
  axios({
    method: 'post',
    url: params.createFavorites(),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data: favorites,
  });

export const fetchFavoritesByLogin = login =>
  axios({
    method: 'get',
    url: params.fetchFavoritesByLogin(login),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

export const deleteFavorites = favoriteId =>
  axios({
    method: 'delete',
    url: params.deleteFavorites(favoriteId),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

export const updateFavorites = (favoriteId, data) =>
  axios({
    method: 'put',
    url: params.updateFavorites(favoriteId),
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data,
  });

export const fetchBackendVersion = () =>
  axios({
    method: 'get',
    url: params.getVersion(),
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });
