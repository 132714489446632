import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import App from './components/App';
import './lib/i18n';
import setLogLevel from './logger';

setLogLevel();

cssVars();

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
