import * as actionTypes from '../constants/actionTypes';

export const logout = () => ({
  type: actionTypes.LOG_OUT,
});

export const resetApiError = () => ({
  type: actionTypes.RESET_API_ERROR,
});

export const doHandleApiError = (message, status, original) => ({
  type: actionTypes.API_ERROR,
  message,
  status,
  original,
});

export const handleApiError = payload => dispatch => {
  let message = 'default';
  if (!payload.request || !payload.request.status) {
    message = 'networkError';
  }
  if (payload.response && payload.response.status) {
    switch (payload.response.status) {
      case 500:
        message = '500';
        break;
      case 400:
        message = '400';
        dispatch(logout());
        break;
      case 401:
        message = '401';
        dispatch(logout());
        break;
      case 403:
        message = '403';
        break;
      case 404:
        message = payload.tokenError ? '404withToken' : '404withoutToken';
        break;
      default:
    }
    dispatch(doHandleApiError(message, payload.response.status, payload));
  } else {
    dispatch(doHandleApiError(message, null, payload));
  }
};
