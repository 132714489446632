import React from 'react';
import PropTypes from 'prop-types';

const Chevron = ({ orientation, ...rest }) => (
  <svg
    height="50px"
    version="1.1"
    viewBox="128 0 256 512"
    width="34px"
    xmlSpace="preserve"
    {...rest}
  >
    {orientation === 'left' && (
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M213.7,256L213.7,256L213.7,256L380.9,81.9c4.2-4.3,4.1-11.4-0.2-15.8l-29.9-30.6c-4.3-4.4-11.3-4.5-15.5-0.2L131.1,247.9  c-2.2,2.2-3.2,5.2-3,8.1c-0.1,3,0.9,5.9,3,8.1l204.2,212.7c4.2,4.3,11.2,4.2,15.5-0.2l29.9-30.6c4.3-4.4,4.4-11.5,0.2-15.8  L213.7,256z"
      />
    )}
    {orientation === 'right' && (
      <path d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7  c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8  L298.3,256z" />
    )}
  </svg>
);

Chevron.propTypes = {
  orientation: PropTypes.string,
};

Chevron.defaultProps = {
  orientation: 'left',
};

export default Chevron;
