import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchUserProfileByLogin } from '../../lib/RestClient';
import { isBlank, convertDateToBackendFormat } from '../../commons';
import constants from '../../constants';
import {
  cancelNewReservation,
  changeReservationRange,
  createReservation,
  getReservationById,
  addNewReservation,
  updateReservation,
  setGuestName,
  toggleGuestSelection,
  addDays,
  toggleSeriesMode,
  postReservationSeries,
  deleteReservation,
} from '../../redux/actions';
import TimeRangeSlider from './TimeRangeSlider';
import GuestEntry from './GuestEntry';
import Button from '../shared/Button';
import ExistingSpaceReservations from './ExistingSpaceReservations';
import EmployeesDropdown from './EmployeesDropdown';
import ReservationDayPicker from '../shared/ReservationDayPicker';
import '../../css/SpaceReservation.css';
import locationIcon from '../../image/location.svg';
import calendarIcon from '../../image/calendar.svg';

class SpaceReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      selectedEmployee: null,
    };
  }

  componentDidMount = async () => {
    const { match, user, isAdmin, history } = this.props;
    const { state } = history.location;
    if (this.isForUpdate()) {
      await this.setSelectedEmployee();
    }
    // When user types URL for reservation manually we should ensure that he/she has rights to view it
    if (this.isForUpdate() && !this.props.id) {
      await this.props.getReservationById(match.params.id, user.login, isAdmin);
    }
    // When user enters manually URL for new reservation, we redirect him/her to 404,
    // otherwise an error will occur (no spaceId and/or spaceName)
    if (!this.isForUpdate() && !state) {
      history.push(constants.PATH.NOT_FOUND);
    }
    // When creating new reservation, spaceName and spaceId are stored in location state,
    // so after refresh the partial data is preserved
    if (!this.isForUpdate() && state && state.spaceId && state.spaceName) {
      this.props.addNewReservation(state.spaceId, state.spaceName);
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { employee } = this.props;
    if (prevProps === this.props) {
      return;
    }
    if (employee !== prevProps.employee) {
      await this.setSelectedEmployee();
    }
    this.setErrorMessage('');
  };

  setSelectedEmployee = async () => {
    const { employee, guest, user } = this.props;
    if (employee === user.login) {
      this.setState({
        selectedEmployee: null,
      });
      return;
    }

    if (employee && !guest.selected) {
      const res = await fetchUserProfileByLogin(employee);
      if (res && res.data) {
        this.setState({
          selectedEmployee: {
            name: `${res.data.firstName} ${res.data.lastName}`,
            login: employee,
          },
        });
      }
    }
  };

  toggleSeriesMode = () => {
    const { date, seriesModeEnabled } = this.props;
    this.props.toggleSeriesMode(!seriesModeEnabled ? date : null);
  };

  onEmployeeSelectionChange = option => {
    if (option) {
      const { value, name, label } = option;
      this.setState({
        selectedEmployee: {
          login: value,
          name,
          label,
        },
      });
      return;
    }
    this.setState({ selectedEmployee: null });
  };

  onGuestSelectionToggle = isGuest => {
    this.props.toggleGuestSelection();
    if (isGuest) {
      this.setState({
        selectedEmployee: null,
      });
    }
  };

  setErrorMessage = msg => {
    this.setState({
      errorMessage: msg,
    });
  };

  generateReservationSeriesArray = reservationData => {
    const reservationSeries = [];
    const seriesStartDay = moment(this.props.seriesStartDay, constants.DATE_FORMAT_FRONTEND);
    const seriesEndDay = moment(this.props.seriesEndDay, constants.DATE_FORMAT_FRONTEND);
    const reservationSeriesLength = seriesEndDay.diff(seriesStartDay, 'days') + 1;
    for (let i = 0; i < reservationSeriesLength; i++) {
      reservationSeries.push({
        ...reservationData,
        date: moment(seriesStartDay.clone().add(i, 'days')).format(constants.DATE_FORMAT_BACKEND),
      });
    }
    return reservationSeries;
  };

  spaceOccupiedMessagePromiseWrapper = (promise, ...args) =>
    promise(...args).then(msg => {
      if (msg) {
        this.setErrorMessage(this.props.translate('reservation.spaceBlocked'));
      }
    });

  saveReservation = () => {
    const {
      id,
      spaceId,
      date,
      startTime,
      endTime,
      user,
      guest,
      comment,
      confirmed,
      seriesModeEnabled,
      translate,
    } = this.props;
    const { selectedEmployee } = this.state;
    if (guest.selected && !guest.name) {
      this.setErrorMessage(translate('reservation.guestEmpty'));
      return Promise.resolve();
    }
    const reservation = {};
    reservation.spaceId = spaceId;
    if (!seriesModeEnabled) {
      reservation.date = convertDateToBackendFormat(date);
    }
    reservation.startTime = startTime;
    reservation.endTime = endTime;
    reservation.employee = selectedEmployee ? selectedEmployee.login : user.login;
    reservation.loginUser = user.login;
    reservation.confirmed = confirmed;
    reservation.guestName = isBlank(guest.name) ? null : guest.name;
    reservation.comment = comment;

    if (this.isForUpdate()) {
      return this.spaceOccupiedMessagePromiseWrapper(this.props.updateReservation, reservation, id);
    }

    if (seriesModeEnabled) {
      const reservationSeriesArray = this.generateReservationSeriesArray(reservation);
      return this.spaceOccupiedMessagePromiseWrapper(
        this.props.postReservationSeries,
        reservationSeriesArray
      );
    }

    return this.spaceOccupiedMessagePromiseWrapper(this.props.createReservation, reservation);
  };

  deleteReservation = () => {
    const { id, history } = this.props;
    return this.props.deleteReservation(id).then(() => {
      this.props.cancelNewReservation();
      history.push('/');
    });
  };

  isForUpdate = () => !!this.props.match.params.id;

  getReservationUserDetails = (selectedEmployee, guest, currentUser) => {
    const userName = `${currentUser.firstName} ${currentUser.lastName}`;
    if (guest && guest.selected) {
      return `${guest.name} ${this.props.translate('by')} ${userName}`;
    }
    if (selectedEmployee) {
      return `${selectedEmployee.name} ${this.props.translate('by')} ${userName}`;
    }
    return currentUser ? userName : '';
  };

  render = () => {
    const {
      date,
      spaceName,
      startTime,
      endTime,
      guest,
      seriesModeEnabled,
      user,
      isAdmin,
      translate,
      history,
    } = this.props;
    const { selectedEmployee } = this.state;
    return (
      <div className="SpaceReservation">
        <div className="SpaceReservation-title">
          <h1 className="SpaceReservation-header">
            {translate(this.isForUpdate() ? 'reservation.edit' : 'reservation.new')}
          </h1>
          <div className="SpaceReservation-details SpaceReservation-spaceNumber">
            <img className="SpaceReservation-detailsImg" src={locationIcon} alt="Space: " />
            <span>{translate('reservation.space', { number: spaceName })}</span>
          </div>
        </div>
        <div>
          <div className="SpaceReservation-detailsBox">
            <div className="SpaceReservation-details SpaceReservation-dateDetails">
              <img className="SpaceReservation-detailsImg" src={calendarIcon} alt="Date: " />
              <div className="SpaceReservation-datePickersContainer">
                <div className="SpaceReservation-datePickerWrapper">
                  {seriesModeEnabled && (
                    <span className="SpaceReservation-datePickerLabel">
                      {translate('reservation.from')}
                    </span>
                  )}
                  <ReservationDayPicker
                    styleVariant="inReservationSeries"
                    valueVariant={seriesModeEnabled ? 'seriesStart' : 'chosenDate'}
                    isAdmin={isAdmin}
                  />
                </div>
                {seriesModeEnabled && (
                  <div className="SpaceReservation-datePickerWrapper">
                    <span className="SpaceReservation-datePickerLabel">
                      {translate('reservation.to')}
                    </span>
                    <ReservationDayPicker
                      styleVariant="inReservationSeries"
                      valueVariant="seriesEnd"
                      isAdmin={isAdmin}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <p className="SpaceReservation-userName">
            {this.getReservationUserDetails(selectedEmployee, guest, user)}
          </p>
          {!seriesModeEnabled && (
            <ExistingSpaceReservations
              history={history}
              date={date}
              isAdmin={isAdmin}
              userLogin={user.login}
              translate={translate}
            />
          )}
          <TimeRangeSlider
            date={date}
            start={startTime}
            end={endTime}
            onRangeChange={this.props.changeReservationRange}
            handleCheckboxCheck={this.toggleSeriesMode}
            seriesModeEnabled={seriesModeEnabled}
            addDays={this.props.addDays}
            setErrorMessage={this.setErrorMessage}
            isForUpdate={this.isForUpdate()}
            customTooltipContainer
            translate={translate}
          />
          {this.state.errorMessage && (
            <p className="SpaceReservation-errorMessage">{this.state.errorMessage}</p>
          )}
          {isAdmin && (
            <EmployeesDropdown
              selectedUser={this.state.selectedEmployee}
              onSelectionChange={this.onEmployeeSelectionChange}
              isDisabled={guest.selected}
              translate={translate}
            />
          )}
          <GuestEntry
            onSelection={this.onGuestSelectionToggle}
            onNameChange={this.props.setGuestName}
            isSelected={guest.selected}
            name={guest.name}
            setErrorMessage={this.setErrorMessage}
            translate={translate}
          />
          <div className="SpaceReservation-mainActions">
            <Button handleClick={this.props.cancelNewReservation}>
              {translate('reservation.cancel')}
            </Button>
            {this.isForUpdate() && (
              <Button handleClick={this.deleteReservation}>
                {translate('reservation.delete')}
              </Button>
            )}
            <Button handleClick={this.saveReservation} active>
              {translate(this.isForUpdate() ? 'reservation.update' : 'reservation.save')}
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

SpaceReservation.propTypes = {
  spaceId: PropTypes.number,
  id: PropTypes.number,
  date: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  spaceName: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  guest: PropTypes.shape({
    selected: PropTypes.bool,
    name: PropTypes.string,
  }),
  employee: PropTypes.string,
  confirmed: PropTypes.bool,
  user: PropTypes.shape({
    clientId: PropTypes.number,
    scope: PropTypes.array,
    tokenId: PropTypes.string,
    userName: PropTypes.number,
    login: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  comment: PropTypes.string,
  isAdmin: PropTypes.bool,
  cancelNewReservation: PropTypes.func.isRequired,
  changeReservationRange: PropTypes.func.isRequired,
  toggleGuestSelection: PropTypes.func.isRequired,
  setGuestName: PropTypes.func.isRequired,
  updateReservation: PropTypes.func.isRequired,
  createReservation: PropTypes.func.isRequired,
  addNewReservation: PropTypes.func.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  getReservationById: PropTypes.func.isRequired,
  addDays: PropTypes.func.isRequired,
  toggleSeriesMode: PropTypes.func.isRequired,
  postReservationSeries: PropTypes.func.isRequired,
  seriesStartDay: PropTypes.string,
  seriesEndDay: PropTypes.string,
  seriesModeEnabled: PropTypes.bool,
  translate: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

SpaceReservation.defaultProps = {
  spaceId: null,
  id: null,
  guest: PropTypes.shape({
    selected: false,
    name: '',
  }),
  employee: null,
  comment: '',
  isAdmin: false,
  spaceName: '',
  confirmed: false,
  startTime: null,
  endTime: null,
  seriesStartDay: null,
  seriesEndDay: null,
  seriesModeEnabled: false,
  translate: () => {},
};

export default connect(state => ({ ...state.handleSingleReservation, ...state.handleUser }), {
  cancelNewReservation,
  changeReservationRange,
  toggleGuestSelection,
  setGuestName,
  updateReservation,
  createReservation,
  addDays,
  toggleSeriesMode,
  postReservationSeries,
  deleteReservation,
  getReservationById,
  addNewReservation,
})(SpaceReservation);
