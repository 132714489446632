import * as actionTypes from '../constants/actionTypes';

const initialState = { favorites: null };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FAVORITES:
      return { ...state, favorites: payload };
    default:
      return state;
  }
};
