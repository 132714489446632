import moment from 'moment';
import * as actionTypes from '../constants/actionTypes';

import constants from '../../constants';

const initialState = {
  chosenDate: moment().format(constants.DATE_FORMAT_FRONTEND),
};
const handleDate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_DAYS:
      return {
        ...state,
        chosenDate: moment(state.chosenDate, constants.DATE_FORMAT_FRONTEND)
          .add(action.days, 'days')
          .format(constants.DATE_FORMAT_FRONTEND),
      };
    case actionTypes.CHANGE_DATE:
      return {
        ...state,
        chosenDate: moment(action.date, constants.DATE_FORMAT_FRONTEND).format(
          constants.DATE_FORMAT_FRONTEND
        ),
      };
    default:
      return state;
  }
};

export default handleDate;
