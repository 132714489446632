import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from '../../constants';
import {
  searchReservations,
  deleteReservation,
  editReservation,
  handleApiError,
  getUsersDetails,
} from '../../redux/actions';
import Reservation from './Reservation';
import '../../css/ReservationsContainer.css';

class ReservationsContainer extends Component {
  componentDidMount = () => {
    const { isAdmin, date } = this.props;
    const filters = this.resolveFilters(date)[isAdmin ? 'admin' : 'user'];
    this.props.searchReservations(filters).then(res => {
      res.length && this.props.getUsersDetails(res);
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { isAdmin, date, login } = this.props;
    const filters = this.resolveFilters(date, login)[isAdmin ? 'admin' : 'user'];
    if (isAdmin && date !== prevProps.date) {
      this.props.searchReservations(filters).then(res => {
        res.length && this.props.getUsersDetails(res);
      });
    }
  };

  resolveFilters = chosenDate => {
    const convertDate = date =>
      moment(date, constants.DATE_FORMAT_FRONTEND).format(constants.DATE_FORMAT_BACKEND);
    const today = convertDate(moment());
    return {
      admin: {
        spaceIds: [],
        employees: [],
        startDate: convertDate(chosenDate),
        endDate: convertDate(chosenDate),
      },
      user: {
        spaceIds: [],
        employees: [this.props.login],
        startDate: today,
        endDate: null,
      },
    };
  };

  editReservation = reservation => {
    const reservationDate = moment(reservation.date, constants.DATE_FORMAT_BACKEND).format(
      constants.DATE_FORMAT_FRONTEND
    );
    this.props.changeDate(reservationDate);
    this.props.editReservation(reservation);
    this.props.history.push(`reservation/${reservation.id}`);
  };

  reservationsAsDayEntries = () => {
    const { reservations } = this.props;
    const map = new Map();
    // eslint-disable-next-line
    reservations.map(reservation => {
      const date = moment(reservation.date, 'YYYY-MM-DD').format('YYYY-MM');
      !map.has(date)
        ? map.set(date, [reservation])
        : map.set(date, [...map.get(date), reservation]);
    });

    return Array.from(map, entry => ({
      date: entry[0],
      dateToDisplay: moment(entry[0], 'YYYY-MM').format(`MMMM 'YY`),
      reservations: entry[1],
    })).sort(this.compareByDate);
  };

  compareByDate = (a, b) => {
    if (a.date > b.date) {
      return 1;
    }

    if (a.date < b.date) {
      return -1;
    }

    return 0;
  };

  generateReservationsList = reservations => {
    return reservations.map(reservation => (
      <Reservation
        key={reservation.id}
        reservation={reservation}
        onEditClicked={this.editReservation}
        onDeleteClicked={this.props.deleteReservation}
        isAdmin={this.props.isAdmin}
        handleApiError={this.props.handleApiError}
        translate={this.props.translate}
      />
    ));
  };

  render = () => {
    const { isAdmin, reservations, translate } = this.props;
    const dayEntries = !isAdmin && this.reservationsAsDayEntries();
    return (
      <div className="ReservationsContainer">
        {reservations.length === 0 && (
          <p className="ReservationsContainer-noReservations">{translate('noReservations')}</p>
        )}
        {dayEntries &&
          dayEntries.map(dayEntry => (
            <div key={dayEntry.date} className="ReservationsContainer-reservationsList">
              <div className="ReservationsContainer-monthName">{dayEntry.dateToDisplay}</div>
              {dayEntry.reservations && this.generateReservationsList(dayEntry.reservations)}
            </div>
          ))}
        {isAdmin && (
          <div className="ReservationsContainer-reservationsList">
            {this.generateReservationsList(reservations)}
          </div>
        )}
      </div>
    );
  };
}

ReservationsContainer.propTypes = {
  searchReservations: PropTypes.func.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  editReservation: PropTypes.func.isRequired,
  handleApiError: PropTypes.func.isRequired,
  getUsersDetails: PropTypes.func.isRequired,
  changeDate: PropTypes.func,
  isAdmin: PropTypes.bool,
  date: PropTypes.string,
  login: PropTypes.string,
  reservations: PropTypes.arrayOf(PropTypes.object),
  translate: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
};

ReservationsContainer.defaultProps = {
  changeDate: () => {},
  isAdmin: false,
  date: '',
  login: '',
  reservations: null,
  translate: () => {},
};

export default connect(
  state => ({
    reservations: state.handleReservations.reservations,
    login: state.handleUser.user.login,
  }),
  {
    searchReservations,
    deleteReservation,
    editReservation,
    handleApiError,
    getUsersDetails,
  }
)(ReservationsContainer);
