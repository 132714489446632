import React from 'react';
import PropTypes from 'prop-types';
import { resolveSpaceColorClass } from '../../commons';
import carIcon from '../../image/car.svg';
import bikeIcon from '../../image/bike.svg';
import '../../css/Space.css';

const getIcon = type => {
  switch (type) {
    case 'car':
      return carIcon;
    case 'bike':
      return bikeIcon;
    default:
      return carIcon;
  }
};

const Space = ({ space, onSpaceClicked, translate }) => (
  <button
    type="button"
    className={`Space Space--${resolveSpaceColorClass(space.state)}`}
    onClick={() => onSpaceClicked(space.id, space.name)}
  >
    <img src={getIcon(space.type)} alt={space.type} />
    <span>{translate('space', { number: space.name })}</span>
  </button>
);

Space.propTypes = {
  space: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    state: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onSpaceClicked: PropTypes.func,
  translate: PropTypes.func,
};

Space.defaultProps = {
  onSpaceClicked: () => {},
  translate: () => {},
};

export default Space;
