import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import '../../css/AppBar.css';
import constants from '../../constants';
import { cancelNewReservation } from '../../redux/actions';
import ReservationDayPicker from '../shared/ReservationDayPicker';
import QuickReservationButton from '../shared/QuickReservationButton';
import Button from '../shared/Button';
import refreshIcon from '../../image/refresh.svg';
import calendar1Day from '../../image/calendar_1_day.svg';
import calendar31Days from '../../image/calendar_31_days.svg';

class AppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.resetMessage();
    }
  }

  resetMessage = () => this.setState({ message: '' });

  // It's not very nice 'hack', but in current version of react-router there is no way to reload a path.
  refreshData = currentPath => {
    const { history } = this.props;
    history.replace('/empty');
    setTimeout(() => {
      history.replace(currentPath);
    });
  };

  isTabButtonActive = expectedPath => this.props.location.pathname === expectedPath;

  openPath = path => evt => {
    this.props.cancelNewReservation();
    this.props.history.push(path);
  };

  /* eslint-disable no-shadow */

  render() {
    const {
      openSidenav,
      location,
      login,
      isAdmin,
      cancelNewReservation,
      staticContext,
      translate,
      ...rest
    } = this.props;
    return (
      <>
        <div className="AppBar" {...rest}>
          <div className="AppBar-top">
            <button type="button" className="AppBar-topButton" onClick={openSidenav}>
              &#9776;
            </button>
            <QuickReservationButton
              className="AppBar-topButton"
              days={1}
              setMessage={message => this.setState({ message })}
            >
              <img src={calendar1Day} alt="calendar" />
            </QuickReservationButton>
            <button
              type="button"
              className="AppBar-topButton"
              onClick={() => {
                this.refreshData(location.pathname);
              }}
            >
              <img src={refreshIcon} alt="Refresh" />
            </button>
            <QuickReservationButton
              className="AppBar-topButton"
              days={31}
              setMessage={message => this.setState({ message })}
            >
              <img src={calendar31Days} alt="calendar" />
            </QuickReservationButton>
            <ReservationDayPicker styleVariant="inAppBar" isAdmin={isAdmin} />
          </div>
          <div className="AppBar-tabs">
            <Button
              active={this.isTabButtonActive(constants.PATH.SPACES)}
              handleClick={this.openPath(constants.PATH.SPACES)}
              tabButton
            >
              {translate('tabs.spaces')}
            </Button>
            <Button
              active={this.isTabButtonActive(constants.PATH.PLAN_MAP)}
              handleClick={this.openPath(constants.PATH.PLAN_MAP)}
              tabButton
            >
              {translate('tabs.map')}
            </Button>
            <Button
              active={this.isTabButtonActive(constants.PATH.RESERVATIONS)}
              handleClick={this.openPath(constants.PATH.RESERVATIONS)}
              tabButton
            >
              {translate(isAdmin ? 'tabs.reservationsAdmin' : 'tabs.reservationsUser')}
            </Button>
          </div>
        </div>
        <p className="AppBar__message">{translate(this.state.message)}</p>
      </>
    );
  }
}

AppBar.propTypes = {
  cancelNewReservation: PropTypes.func.isRequired,
  openSidenav: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  login: PropTypes.string,
  isAdmin: PropTypes.bool,
  staticContext: PropTypes.object,
  translate: PropTypes.func,
  pathname: PropTypes.string,
};

AppBar.defaultProps = {
  openSidenav: () => {},
  login: '',
  isAdmin: false,
  staticContext: undefined,
  translate: () => {},
  pathname: '',
};

export default withRouter(
  connect(null, {
    cancelNewReservation,
  })(AppBar)
);
