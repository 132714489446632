import * as actionTypes from '../constants/actionTypes';
import * as RestClient from '../../lib/RestClient';
import { requestWrapper, handleGlobalSpinner } from './helperActions';
import { handleApiError } from './errorActions';
import { generateUuid } from '../../commons';

export const setFavorites = payload => ({
  type: actionTypes.SET_FAVORITES,
  payload,
});

export const fetchFavoritesByLogin = login => dispatch => {
  const actionId = generateUuid('action');
  dispatch(handleGlobalSpinner(actionId, true));
  return RestClient.fetchFavoritesByLogin(login)
    .then(response => {
      dispatch(handleGlobalSpinner(actionId, false));
      dispatch(setFavorites(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch(handleGlobalSpinner(actionId, false));
      if (!error.response || error.response.status !== 404) {
        dispatch(handleApiError(error));
        return error;
      }
      return false;
    });
};

export const createFavorites = favorites => dispatch => {
  return requestWrapper(
    RestClient.createFavorites(favorites).then(response => {
      dispatch(setFavorites(response.data));
      return response.data;
    })
  )(dispatch);
};

export const updateFavorites = (favoriteId, data) => dispatch => {
  return requestWrapper(
    RestClient.updateFavorites(favoriteId, data).then(response => {
      dispatch(setFavorites(response.data));
      return response.data;
    })
  )(dispatch);
};

export const deleteFavorites = favoriteId => dispatch => {
  return requestWrapper(
    RestClient.deleteFavorites(favoriteId).then(response => {
      dispatch(setFavorites(null));
      return response.data;
    })
  )(dispatch);
};
