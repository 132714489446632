import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSpaces, addNewReservation, fetchPlanList, setActivePlan } from '../../redux/actions';
import PlanSvg from './PlanSvg';
import PlanSpace from './PlanSpace';
import SvgZoom from './SvgZoom';
import Button from '../shared/Button';
import '../../css/PlanContainer.css';

class PlanContainer extends Component {
  componentDidMount = () => {
    const { date, planList } = this.props;
    this.props.fetchSpaces(date);
    if (planList.length === 0) {
      this.props.fetchPlanList();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.date !== prevProps.date) {
      this.props.fetchSpaces(this.props.date);
    }
  };

  onPlanChange = planId => {
    const { activePlanId, plans } = this.props;
    if (planId !== activePlanId) {
      this.props.setActivePlan(planId, plans);
    }
  };

  onSpaceClick = space => {
    // eslint-disable-next-line no-shadow
    const { history, addNewReservation } = this.props;
    addNewReservation(space.id, space.name);
    history.push('/reservation', { spaceId: space.id, spaceName: space.name });
  };

  activePlan = (plans, activePlanId) => plans.find(plan => plan.id === activePlanId);

  activePlanSpaces = (plans, activePlanId) =>
    this.props.spaces.filter(space => space.planId === activePlanId && space.assigned === true);

  render = () => {
    const { plans, activePlanId, planList, translate } = this.props;
    const activePlan = this.activePlan(plans, activePlanId);
    const activePlanSpaces = this.activePlanSpaces(plans, activePlanId);
    return (
      <div className="PlanContainer">
        {planList.length > 1 && (
          <div className="PlanContainer-planPicker">
            {planList.map(plan => (
              <Button
                key={plan.id}
                long
                active={plan.id === activePlanId}
                handleClick={evt => this.onPlanChange(plan.id)}
              >
                {translate('garage', { number: plan.id })}
              </Button>
            ))}
          </div>
        )}
        <div className="PlanContainer-planView">
          {activePlan && (
            <SvgZoom mouseZoomRatio={1.2}>
              <PlanSvg svg={activePlan.plan}>
                {activePlanSpaces.map(space => (
                  <PlanSpace
                    key={space.id}
                    space={space}
                    onSpaceClick={evt => this.onSpaceClick(space)}
                  />
                ))}
              </PlanSvg>
            </SvgZoom>
          )}
        </div>
      </div>
    );
  };
}

PlanContainer.propTypes = {
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      planId: PropTypes.number,
      name: PropTypes.string,
      coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      state: PropTypes.string,
      assigned: PropTypes.bool,
    })
  ),
  date: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  planList: PropTypes.array,
  plans: PropTypes.array,
  activePlanId: PropTypes.number,
  history: PropTypes.object.isRequired,
  fetchSpaces: PropTypes.func.isRequired,
  addNewReservation: PropTypes.func.isRequired,
  fetchPlanList: PropTypes.func.isRequired,
  setActivePlan: PropTypes.func.isRequired,
  translate: PropTypes.func,
};

PlanContainer.defaultProps = {
  spaces: [],
  planList: [],
  plans: [],
  activePlanId: 1,
  translate: () => {},
};

const mapStateToProps = state => ({
  planList: state.handlePlanActions.planList,
  activePlanId: state.handlePlanActions.activePlanId,
  plans: state.handlePlanActions.plans,
  spaces: state.handleSpaces.spaces,
});

export default connect(mapStateToProps, {
  fetchSpaces,
  addNewReservation,
  fetchPlanList,
  setActivePlan,
})(PlanContainer);
