import * as actionTypes from '../constants/actionTypes';

const initialState = {
  backendVersion: {
    tag: '',
    shaShort: '',
  },
};

export default (state = initialState, { type, version }) => {
  switch (type) {
    case actionTypes.SET_BACKEND_VERSION:
      return { ...state, backendVersion: version };
    default:
      return state;
  }
};
