import * as actionTypes from '../constants/actionTypes';
import * as RestClient from '../../lib/RestClient';
import { requestWrapper } from './helperActions';

export const setActivePlan = (planId, plans) => dispatch => {
  const isPlanFetched = plans ? plans.findIndex(plan => plan.id === planId) !== -1 : false;
  if (!isPlanFetched) {
    dispatch(fetchPlan(planId));
  }
  dispatch(setActivePlanSuccess(planId));
};

export const fetchPlan = id => dispatch => {
  return requestWrapper(
    RestClient.fetchPlan(id).then(response => {
      dispatch(fetchPlanSuccess(id, response.data));
      return response.data;
    })
  )(dispatch);
};

export const fetchPlanList = () => dispatch => {
  return requestWrapper(
    RestClient.fetchPlanList().then(response => {
      dispatch(fetchPlanListSuccess(response.data));
      const activePlanId = response.data[0].id; // By default the first fetched plan is set as active.
      dispatch(setActivePlan(activePlanId));
      return response.data;
    })
  )(dispatch);
};

export const fetchPlanListSuccess = planList => ({
  type: actionTypes.PLAN_LIST_GET_SUCCESS,
  planList,
});

export const fetchPlanSuccess = (id, plan) => ({
  type: actionTypes.PLAN_GET_SUCCESS,
  id,
  plan,
});

export const setActivePlanSuccess = planId => ({
  type: actionTypes.SET_ACTIVE_PLAN,
  planId,
});
