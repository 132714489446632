import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../css/Reservation.css';
import editIcon from '../../image/edit.svg';
import trashIcon from '../../image/trash.svg';
import { resolveSpaceColorClass } from '../../commons';

const toString = (dateTime, dateTimeFormat, stringFormat) =>
  moment(dateTime, dateTimeFormat).format(stringFormat);

const fromTime = (time, format) => toString(time, 'HH:mm:ss', format);

const fromDate = (date, format) => toString(date, 'YYYY-MM-DD', format);

class Reservation extends Component {
  getDisplayableUser = user => (user ? `${user.firstName} ${user.lastName}` : null);

  renderUserName = (guest, user, reservationOwner) => {
    const reservationUser = guest || user;
    const reservationCreatedBy = reservationOwner;
    return (
      <div>
        <h3 className="Reservation-userName">{reservationUser}</h3>
        {reservationCreatedBy && (
          <h4 className="Reservation-createdBy">
            {`(${this.props.translate('by')} ${reservationCreatedBy})`}
          </h4>
        )}
      </div>
    );
  };

  render() {
    const { reservation, onEditClicked, onDeleteClicked, isAdmin } = this.props;
    const {
      employeeDetails,
      reservationOwnerDetails,
      employee,
      updatedBy,
      spaceName,
      spaceState,
      guestName,
      date,
      startTime,
      endTime,
      id,
    } = reservation;
    const userName = this.getDisplayableUser(employeeDetails);
    const reservationOwner =
      (employee !== updatedBy || guestName) && this.getDisplayableUser(reservationOwnerDetails);
    const reservationSpaceCssClass = resolveSpaceColorClass(spaceState);
    return (
      <div className="Reservation">
        <div className="Reservation-details">
          <div className="Reservation-leftColumn">
            <div className={`Reservation-space Reservation-space--${reservationSpaceCssClass}`}>
              {spaceName}
            </div>
            <div className="Reservation-date">
              <div className="Reservation-userDetails">
                {isAdmin
                  ? this.renderUserName(guestName, userName, reservationOwner)
                  : fromDate(date, 'DD.MM.')}
              </div>
              <div className="Reservation-time">
                {`${fromTime(startTime, 'HH:mm')}
                - ${fromTime(endTime, 'HH:mm')}`}
              </div>
            </div>
          </div>
          <div className="Reservation-buttonsContainer">
            <button
              className="Reservation-button"
              type="button"
              onClick={() => onEditClicked(reservation)}
            >
              <img className="Reservation-buttonImage" src={editIcon} alt="Edit" />
            </button>
            <button
              className="Reservation-button"
              type="button"
              onClick={() => onDeleteClicked(id)}
            >
              <img className="Reservation-buttonImage" src={trashIcon} alt="Delete" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Reservation.propTypes = {
  reservation: PropTypes.shape({
    id: PropTypes.number,
    spaceId: PropTypes.number.isRequired,
    spaceName: PropTypes.string.isRequired,
    spaceState: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    guestName: PropTypes.string,
    employee: PropTypes.string,
    employeeDetails: PropTypes.object,
    confirmed: PropTypes.bool,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
    reservationOwnerDetails: PropTypes.object,
  }).isRequired,
  onEditClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  isAdmin: PropTypes.bool,
  translate: PropTypes.func,
};

Reservation.defaultProps = {
  onEditClicked: () => {},
  onDeleteClicked: () => {},
  isAdmin: false,
  translate: () => {},
};

export default Reservation;
