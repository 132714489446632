import React from 'react';
import '../../css/Spinner.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Spinner = ({ showGlobalSpinner }) => (
  <>
    {showGlobalSpinner && (
      <>
        <div className="Spinner-overlay" />
        <div className="Spinner" />
      </>
    )}
  </>
);

Spinner.propTypes = {
  showGlobalSpinner: PropTypes.bool.isRequired,
};

export default connect(state => state.handleApplication)(Spinner);
