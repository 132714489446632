import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Favorites from './Favorites';
import '../../css/Sidenav.css';
import akraLogoWhiteRed from '../../image/akra_logo_white_red.svg';
import logoutIcon from '../../image/logout.svg';
import englishFlag from '../../image/gb-flag.svg';
import germanFlag from '../../image/de-flag.svg';
import polishFlag from '../../image/pl-flag.svg';

const Sidenav = ({
  open,
  onLogout,
  onClose,
  onOpenAbout,
  onLanguageChange,
  userName,
  login,
  children,
  translate,
}) => (
  <Drawer open={open} onClose={onClose}>
    <div className="Sidenav">
      <div className="Sidenav-header">
        <a href="//www.akra.de/" title="akra website">
          <img src={akraLogoWhiteRed} alt="AKRA GmbH" className="Sidenav-akraLogo" />
        </a>
        <p className="Sidenav-spacesTitle">SPACES</p>
        <button type="button" className="Sidenav-closeButton" onClick={onClose}>
          &times;
        </button>
        {login && (
          <p className="Sidenav-greeting">{translate('sidenav.greeting', { user: userName })}</p>
        )}
      </div>
      <div className="Sidenav-content">
        <Favorites translate={translate} />
      </div>
      <div className="Sidenav-content">
        <div className="Sidenav-languageSelection">
          <button type="button" onClick={() => onLanguageChange('en-GB')}>
            <img src={englishFlag} alt="English flag" />
          </button>
          <button type="button" onClick={() => onLanguageChange('de')}>
            <img src={germanFlag} alt="German flag" />
          </button>
          <button type="button" onClick={() => onLanguageChange('pl')}>
            <img src={polishFlag} alt="Polish flag" />
          </button>
        </div>
        <a href="mailto:spaces@akra.de" className="Sidenav-actionButton" rel="noopener noreferer">
          Feedback
        </a>
        <button className="Sidenav-actionButton" type="button" onClick={onOpenAbout}>
          {translate('sidenav.about')}
        </button>
        {login && (
          <button className="Sidenav-actionButton" type="button" onClick={onLogout}>
            <img src={logoutIcon} alt="Logout: " className="Sidenav-actionButtonImage" />
            {translate('sidenav.logout')}
          </button>
        )}
      </div>
    </div>
  </Drawer>
);

Sidenav.propTypes = {
  open: PropTypes.bool,
  onLogout: PropTypes.func,
  onClose: PropTypes.func,
  onOpenAbout: PropTypes.func,
  userName: PropTypes.string,
  children: PropTypes.node,
  onLanguageChange: PropTypes.func,
  translate: PropTypes.func,
  login: PropTypes.string,
};

Sidenav.defaultProps = {
  open: false,
  onLogout: () => {},
  onClose: () => {},
  onOpenAbout: () => {},
  onLanguageChange: () => {},
  userName: '',
  children: undefined,
  translate: () => {},
  login: '',
};

export default Sidenav;
