import React from 'react';
import PropTypes from 'prop-types';
import { resolveSpaceColorClass } from '../../commons';
import '../../css/PlanSpace.css';

const PlanSpace = ({ space, onSpaceClick }) => {
  const stateClass = resolveSpaceColorClass(space.state);
  const centerX = space.coordinates.x + space.coordinates.width / 2;
  const centerY = space.coordinates.y + space.coordinates.height / 2;
  const rotate = space.coordinates.rotate || 0;
  return (
    <g className={`PlanSpace PlanSpace--${stateClass}`} onClick={onSpaceClick}>
      <rect
        style={{ transform: `rotate(${rotate}deg)`, transformOrigin: `${centerX}px ${centerY}px` }}
        x={space.coordinates.x}
        y={space.coordinates.y}
        width={space.coordinates.width}
        height={space.coordinates.height}
      />
      <text x={centerX - 15} y={centerY + 5} className="PlanSpace-text">
        {space.name}
      </text>
    </g>
  );
};

PlanSpace.propTypes = {
  space: PropTypes.shape({
    id: PropTypes.number,
    planId: PropTypes.number,
    name: PropTypes.string,
    coordinates: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      height: PropTypes.number,
      width: PropTypes.number,
      rotate: PropTypes.number,
    }),
    state: PropTypes.string,
    assigned: PropTypes.bool,
  }).isRequired,
  onSpaceClick: PropTypes.func,
};

PlanSpace.defaultProps = {
  onSpaceClick: () => {},
};

export default PlanSpace;
