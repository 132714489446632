import React, { Component } from 'react';
// package react-virtualized-select is no longer supported,
// but popular react-select has performance issues
import VirtualizedSelect from 'react-virtualized-select';
import PropTypes from 'prop-types';
import { fetchFilteredUsers } from '../../lib/RestClient';
import constants from '../../constants';
import '../../css/EmployeesDropdown.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';

class EmployeesDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
    };
  }

  async componentDidMount() {
    await fetchFilteredUsers(constants.EMPLOYEES_FILTER).then(response =>
      this.setState({ employees: response.data })
    );
  }

  getDropdownOptions = employees =>
    employees.map(user => ({
      value: `${user.login}`,
      label: `${user.lastName}, ${user.firstName}`,
      name: `${user.firstName} ${user.lastName}`,
    }));

  render() {
    const { employees } = this.state;
    const { selectedUser, translate } = this.props;
    const options = this.getDropdownOptions(employees);
    return (
      <div className="EmployeesDropdown">
        <style>
          {`
          .EmployeesDropdown .Select-clear-zone:before {
            content: '${translate('reservation.clear')}';
            margin-right: 5px;
          }
        `}
        </style>
        <p className="EmployeesDropdown-label">{translate('reservation.employee')}</p>
        <div>
          <VirtualizedSelect
            placeholder={translate('reservation.select')}
            options={options}
            onChange={this.props.onSelectionChange}
            value={selectedUser && selectedUser.login}
            disabled={this.props.isDisabled}
          />
        </div>
      </div>
    );
  }
}

EmployeesDropdown.propTypes = {
  onSelectionChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  selectedUser: PropTypes.shape({
    name: PropTypes.string,
    login: PropTypes.string,
  }),
  translate: PropTypes.func,
};

EmployeesDropdown.defaultProps = {
  onSelectionChange: () => {},
  selectedUser: null,
  isDisabled: false,
  translate: () => {},
};

export default EmployeesDropdown;
