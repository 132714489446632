import { uniq } from 'lodash';
import * as actionTypes from '../constants/actionTypes';
import * as RestClient from '../../lib/RestClient';
import { redirect, requestWrapper } from './helperActions';
import constants from '../../constants';

export const fetchReservations = forDate => dispatch => {
  return requestWrapper(
    RestClient.fetchReservations(forDate).then(response => {
      dispatch(fetchReservationsSuccess(response.data));
      return response.data;
    })
  )(dispatch);
};

export const searchReservations = filters => dispatch => {
  return requestWrapper(
    RestClient.searchReservations(filters).then(response => {
      dispatch(fetchReservationsSuccess(response.data));
      return response.data;
    })
  )(dispatch);
};

export const getUsersDetails = reservations => dispatch => {
  const reservationsLogins = [];
  reservations.map(r => reservationsLogins.push(...[r.employee, r.updatedBy]));
  return requestWrapper(
    RestClient.fetchFilteredUsers({ logins: uniq(reservationsLogins) }).then(res => {
      const usersDetails = res.data;
      const reservationsWithUsersDetails = reservations.map(r => ({
        ...r,
        employeeDetails: usersDetails.find(user => user.login === r.employee),
        reservationOwnerDetails: usersDetails.find(user => user.login === r.updatedBy),
      }));
      dispatch(fetchReservationsSuccess(reservationsWithUsersDetails));
      return reservationsWithUsersDetails;
    })
  )(dispatch);
};

export const fetchReservationsSuccess = reservations => ({
  type: actionTypes.RESERVATIONS_GET_SUCCESS,
  reservations,
});

export const deleteReservation = reservationId => dispatch => {
  return requestWrapper(
    RestClient.deleteReservation(reservationId).then(response => {
      dispatch(deleteReservationSuccess(reservationId));
    })
  )(dispatch);
};

export const deleteReservationSuccess = reservationId => ({
  type: actionTypes.RESERVATION_DELETE_SUCCESS,
  reservationId,
});

export const addNewReservation = (spaceId, spaceName) => ({
  type: actionTypes.ADD_NEW_RESERVATION,
  spaceId,
  spaceName,
});

export const editReservation = reservation => ({
  type: actionTypes.START_RESERVATION_EDIT,
  reservation,
});

export const getReservationById = (id, userLogin, isAdmin) => dispatch => {
  return requestWrapper(
    RestClient.fetchReservationById(id)
      .then(response => {
        // Check if user has rights to access target reservation's URL
        const { employee, createdBy } = response.data;
        if (isAdmin || employee === userLogin || createdBy === userLogin) {
          dispatch(editReservation(response.data));
        } else {
          redirect(constants.PATH.NOT_FOUND);
        }
        return response.data;
      })
      .catch(error => {
        redirect(constants.PATH.NOT_FOUND);
        throw error;
      })
  )(dispatch);
};

export const cancelNewReservation = () => {
  redirect();
  return {
    type: actionTypes.CANCEL_NEW_RESERVATION,
  };
};

export const toggleSeriesMode = seriesDay => ({
  type: actionTypes.TOGGLE_SERIES_MODE,
  seriesDay,
});

export const setSeriesStartDay = startDay => ({
  type: actionTypes.SET_SERIES_START_DAY,
  startDay,
});

export const setSeriesEndDay = endDay => ({
  type: actionTypes.SET_SERIES_END_DAY,
  endDay,
});

export const setGuestName = guestName => ({
  type: actionTypes.SET_GUEST_NAME,
  guestName,
});

export const toggleGuestSelection = () => ({
  type: actionTypes.TOGGLE_GUEST_SELECTION,
});

export const changeReservationRange = (startTime, endTime) => ({
  type: actionTypes.CHANGE_RESERVATION_RANGE,
  startTime,
  endTime,
});

export const createReservation = reservation => dispatch => {
  return requestWrapper(
    RestClient.createReservation(reservation).then(response => {
      if (!response.data.serviceMessage) {
        dispatch(saveReservationSuccess(response.data));
        redirect();
      }
      return response.data;
    })
  )(dispatch);
};

export const postReservationSeries = (reservationSeries, isQuick = false) => dispatch => {
  return requestWrapper(
    RestClient.postReservationSeries(reservationSeries, isQuick).then(response => {
      if (!response.data.serviceMessage) {
        dispatch(postReservationSeriesSuccess(response.data.dtos));
        redirect();
      }
      return response.data.serviceMessage;
    })
  )(dispatch);
};

export const postReservationSeriesSuccess = reservationSeries => ({
  type: actionTypes.RESERVATION_SERIES_POST_SUCCESS,
  reservationSeries,
});

export const saveReservationSuccess = reservation => ({
  type: actionTypes.RESERVATION_POST_SUCCESS,
  reservation,
});

export const updateReservation = (reservation, id) => dispatch => {
  return requestWrapper(
    RestClient.updateReservation(reservation, id).then(response => {
      if (!response.data.serviceMessage) {
        dispatch(updateReservationSuccess(response.data, id));
        redirect();
      }
      return response.data.serviceMessage;
    })
  )(dispatch);
};

export const updateReservationSuccess = (reservation, id) => ({
  type: actionTypes.RESERVATION_PUT_SUCCESS,
  reservation,
  id,
});

export const fetchExistingSpaceReservationsSuccess = existingSpaceReservations => ({
  type: actionTypes.FETCH_EXISTING_SPACE_RESERVATIONS_SUCCESS,
  existingSpaceReservations,
});
