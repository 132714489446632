import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  TokenRequest,
  LocalStorageBackend,
  FetchRequestor,
} from '@openid/appauth';

import { params } from '../commons';
import SpacesTokenRequestHandler from './spacesTokenRequestHandler';
import SpacesBasicQueryStringUtils from './spacesBasicQueryStringUtils';
import store from '../redux/store';

const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code';
const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';

export class AuthorizationService {
  notifier = new AuthorizationNotifier();

  authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new SpacesBasicQueryStringUtils()
  );

  tokenHandler = new SpacesTokenRequestHandler(
    new FetchRequestor(),
    new SpacesBasicQueryStringUtils()
  );

  configuration = new AuthorizationServiceConfiguration({
    authorization_endpoint: params.oauth2AuthorizeUrl(),
    token_endpoint: params.oauth2TokenUrl(),
    revocation_endpoint: '',
    userinfo_endpoint: '',
    end_session_endpoint: '',
  });

  constructor(storeRef) {
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    this.notifier.setAuthorizationListener((request, response, error) => {
      this.request = request;
      this.response = response;
      this.error = error;
    });
    this.store = storeRef;
  }

  authorize = () => {
    const request = new AuthorizationRequest({
      client_id: params.oauth2ClientId(),
      redirect_uri: params.oauth2RedirectUrl(),
      scope: params.oauth2Scopes(),
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      state: null,
      extras: { prompt: 'consent', access_type: 'offline' },
    });

    this.authorizationHandler.performAuthorizationRequest(this.configuration, request);
  };

  fetchTokens = (authorizationCode, codeVerifier) => {
    const extras = codeVerifier && { code_verifier: codeVerifier };
    const request = new TokenRequest({
      client_id: params.oauth2ClientId(),
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code: authorizationCode,
      refresh_token: null,
      redirect_uri: params.oauth2RedirectUrl(),
      extras: {
        scope: params.oauth2Scopes(),
        ...extras,
      },
    });

    return this.tokenHandler.performTokenRequest(this.configuration, request);
  };

  refreshAccessToken = () => {
    const { refreshToken } = this.store.getState().handleAuthorization;
    if (!refreshToken) {
      return Promise.reject(
        new Error('The session could not be refreshed because the refresh token is missing.')
      );
    }

    const request = new TokenRequest({
      client_id: params.oauth2ClientId(),
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      code: null,
      refresh_token: refreshToken,
    });

    return this.tokenHandler.performTokenRequest(this.configuration, request);
  };

  completeAuthorizationAndFetchTokens = () => {
    return this.authorizationHandler.completeAuthorizationRequestIfPossible().then(() => {
      if (this.response) {
        const codeVerifier =
          this.request && this.request.internal && this.request.internal.code_verifier;
        return this.fetchTokens(this.response.code, codeVerifier);
      }
      return Promise.reject(this.error || 'Authorization request not completed');
    });
  };
}

const authService = new AuthorizationService(store);
export default authService;
