import * as actionTypes from '../constants/actionTypes';
import { generateUuid } from '../../commons';
import history from '../../lib/history';
import { handleApiError } from './errorActions';
import { fetchBackendVersion } from '../../lib/RestClient';

export const redirect = (route = '/') => history.push(route);

export const requestWrapper = request => dispatch => {
  const actionId = generateUuid('action');
  dispatch(handleGlobalSpinner(actionId, true));
  return request
    .then(response => {
      dispatch(handleGlobalSpinner(actionId, false));
      return response;
    })
    .catch(error => {
      dispatch(handleApiError(error));
      dispatch(handleGlobalSpinner(actionId, false));
      return error;
    });
};

export const handleGlobalSpinner = (actionId, visible) => ({
  type: actionTypes.HANDLE_GLOBAL_SPINNER,
  actionId,
  visible,
});

export const changeOnlineStatus = isOnline => ({
  type: actionTypes.CHANGE_ONLINE_STATUS,
  isOnline,
});

export const getBackendVersion = () => dispatch => {
  requestWrapper(
    fetchBackendVersion().then(response => {
      dispatch(setBackendVersion(response.data));
      return response.data;
    })
  )(dispatch);
};

export const setBackendVersion = version => ({
  type: actionTypes.SET_BACKEND_VERSION,
  version,
});

export const setFrontendVersion = version => ({
  type: actionTypes.SET_FRONTEND_VERSION,
  version,
});
