import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import store from '../redux/store';
import '../css/index.css';
import MainLayout from './main/MainLayout';
import history from '../lib/history';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <MainLayout />
      </MuiThemeProvider>
    </Router>
  </Provider>
);

export default App;
