import React, { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Offline } from 'react-detect-offline';
import { iPhonePopUpCookieProps, languageCookieName, getRedirectRoute } from '../../commons';
import constants from '../../constants';
import Spinner from './Spinner';
import ErrorMessage from './ErrorMessage';
import Sidenav from './Sidenav';
import {
  logout,
  changeDate,
  cancelNewReservation,
  changeOnlineStatus,
  fetchFavoritesByLogin,
} from '../../redux/actions';
import SpacesContainer from '../spaces/SpacesContainer';
import PlanContainer from '../plan/PlanContainer';
import ReservationsContainer from '../reservations/ReservationsContainer';
import SpaceReservation from '../reservation/SpaceReservation';
import Token from '../token/Token';
import About from '../about/About';
import AppBar from './AppBar';
import NotFound from '../404/NotFound';
import AuthorizedRoute from './AuthorizedRoute';
import AddToHomescreen from './AddToHomescreen';
import '../../css/MainLayout.css';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidenavOpen: false,
      addToHomescreenShown: false,
    };
  }

  componentDidMount() {
    const isCookieBlockingAddToHomescreen = Cookies.get(iPhonePopUpCookieProps().name);
    if (this.isIphone() && !window.navigator.standalone && !isCookieBlockingAddToHomescreen) {
      setTimeout(() => this.showAddToHomescreen(), 2000);
    }
    // we're setting up the initial online state based on the dom element rendered by react-detect-offline, this is not
    // the best solution, but at the moment there is no other possibility to do that
    const initialOnlineState = !document.querySelector('.MainLayout-offlineMessage');
    this.changeOnlineStatus(initialOnlineState);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user: { login },
    } = this.props || { user: { login: '' } };
    if (prevProps.user.login !== login) {
      login && this.props.fetchFavoritesByLogin(login);
    }
  }

  isIphone = () => {
    const userPlatform = window.navigator.platform.toLowerCase();
    return ['iphone', 'ipad', 'ipod'].includes(userPlatform);
  };

  showAddToHomescreen = () => {
    this.setState({ addToHomescreenShown: true });
  };

  closeAddToHomescreen = () => {
    const { name, lifetime } = iPhonePopUpCookieProps();
    this.setState({ addToHomescreenShown: false });
    Cookies.set(name, 'true', { expires: lifetime });
  };

  openSidenav = () =>
    this.setState({
      sidenavOpen: true,
    });

  closeSidenav = () =>
    this.setState({
      sidenavOpen: false,
    });

  openAbout = evt => {
    this.closeSidenav();
    this.props.history.push(constants.PATH.ABOUT);
  };

  logout = evt => {
    this.closeSidenav();
    this.props.logout();
  };

  changeLanguage = lng => {
    this.props.i18n.changeLanguage(lng);
    Cookies.set(languageCookieName, lng, { expires: 365 });
    const { months, weekdaysMin } = constants.MOMENT_LOCALE_DATA[lng];
    moment.updateLocale(lng, { months, weekdaysMin });
  };

  changeOnlineStatus = isOnline => {
    this.props.changeOnlineStatus(isOnline);
  };

  render = () => {
    const { sidenavOpen, addToHomescreenShown } = this.state;
    const { chosenDate, user, t, history, i18n } = this.props;
    const isAdmin = user.scope.indexOf('spaces:admin') > -1;
    return (
      <div className="MainLayout">
        <Spinner />
        <ErrorMessage translate={t} />
        <AddToHomescreen
          handleClose={this.closeAddToHomescreen}
          isShown={addToHomescreenShown}
          translate={t}
        />
        <Sidenav
          userName={`${user.firstName} ${user.lastName}`}
          login={user && user.login}
          open={sidenavOpen}
          onClose={this.closeSidenav}
          onLogout={this.logout}
          onOpenAbout={this.openAbout}
          onLanguageChange={this.changeLanguage}
          translate={t}
        />
        <div className="MainLayout-header">
          <AppBar
            openSidenav={this.openSidenav}
            cancelNewReservation={this.props.cancelNewReservation}
            isAdmin={isAdmin}
            translate={t}
          />
          <Offline onChange={this.changeOnlineStatus}>
            <div className="MainLayout-offlineMessage">{t('offlineMessage')}</div>
          </Offline>
        </div>
        <div className="MainLayout-tabContent">
          <Switch>
            <Route exact path="/" render={() => <Redirect to={getRedirectRoute()} />} />
            <Route exact path={constants.PATH.TOKEN} component={Token} />
            <Route exact path={constants.PATH.ABOUT} component={About} />
            <AuthorizedRoute
              exact
              path={constants.PATH.SPACES}
              render={() => (
                <SpacesContainer
                  translate={t}
                  isAdmin={isAdmin}
                  date={chosenDate}
                  history={history}
                />
              )}
            />
            <AuthorizedRoute
              exact
              path={constants.PATH.PLAN_MAP}
              render={() => (
                <PlanContainer translate={t} isAdmin={isAdmin} date={chosenDate} {...this.props} />
              )}
            />
            <AuthorizedRoute
              exact
              path={constants.PATH.RESERVATIONS}
              render={match => (
                <ReservationsContainer
                  translate={t}
                  language={i18n.language}
                  isAdmin={isAdmin}
                  date={chosenDate}
                  changeDate={this.props.changeDate}
                  history={history}
                  match={match}
                />
              )}
            />
            <AuthorizedRoute
              exact
              path={constants.PATH.RESERVATION}
              render={match => (
                <SpaceReservation
                  match={match}
                  isAdmin={isAdmin}
                  date={chosenDate}
                  history={history}
                  translate={t}
                />
              )}
            />
            {/* Empty route created in order to force route reload in AppBar container */}
            <Route exact path="/empty" component={null} />
            <Route render={() => <NotFound translate={t} />} />
          </Switch>
        </div>
      </div>
    );
  };
}

MainLayout.propTypes = {
  logout: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  cancelNewReservation: PropTypes.func.isRequired,
  changeOnlineStatus: PropTypes.func.isRequired,
  fetchFavoritesByLogin: PropTypes.func.isRequired,
  chosenDate: PropTypes.string,
  user: PropTypes.shape({
    empId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lifeTime: PropTypes.number,
    login: PropTypes.string,
    scope: PropTypes.arrayOf(PropTypes.string),
    tokenId: PropTypes.string,
    userName: PropTypes.number,
  }),
  t: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
};

MainLayout.defaultProps = {
  user: null,
  chosenDate: null,
  t: () => {},
};

const mapStateToProps = state => ({
  user: state.handleUser.user,
  chosenDate: state.handleDate.chosenDate,
});

const componentWithState = connect(mapStateToProps, {
  logout,
  changeDate,
  cancelNewReservation,
  changeOnlineStatus,
  fetchFavoritesByLogin,
})(withTranslation()(MainLayout));

export { componentWithState as UnwrappedMainLayout };

export default withRouter(componentWithState);
