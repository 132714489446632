import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../../css/AddToHomescreen.css';
import iosShare from '../../image/safari_ios_share.png';

const AddToHomescreen = ({ translate, handleClose, isShown, ...props }) => (
  <Dialog
    fullWidth
    open={isShown}
    onClose={handleClose}
    disableBackdropClick
    className="AddToHomescreen"
  >
    <DialogTitle className="AddToHomescreen-header" disableTypography>
      <DialogActions>
        {' '}
        <button type="button" className="AddToHomescreen-closeButton" onClick={handleClose}>
          &times;
        </button>
      </DialogActions>
      {translate('iosInstall.title')}
    </DialogTitle>
    <DialogContent className="AddToHomescreen-content">
      <DialogContentText className="AddToHomescreen-message">
        {translate('iosInstall.message1')}
        <img className="AddToHomescreen-safariShareIcon" src={iosShare} alt="safari-share-icon" />
        {translate('iosInstall.message2')}
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

AddToHomescreen.propTypes = {
  isShown: PropTypes.bool,
  handleClose: PropTypes.func,
  translate: PropTypes.func,
};

AddToHomescreen.defaultProps = {
  isShown: false,
  handleClose: () => {},
  translate: () => {},
};

export default AddToHomescreen;
