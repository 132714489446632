import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VirtualizedSelect from 'react-virtualized-select';
import {
  fetchFavoritesByLogin,
  createFavorites,
  updateFavorites,
  deleteFavorites,
  fetchSpaces,
} from '../../redux/actions';
import '../../css/Favorites.css';

const defaultFavorites = login => ({
  employee: login,
  endTime: '',
  spaceBackupId: undefined,
  spaceId: undefined,
  startTime: '',
});

class Favorites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: props.favorites || defaultFavorites(props.login),
      message: '',
    };
  }

  componentDidMount = () => {
    const { spaces, chosenDate, login } = this.props;
    !spaces.length && login && this.props.fetchSpaces(chosenDate);
  };

  handleSetFavorites = () => {
    const globalFavorites = this.props.favorites;
    const localFavorites = this.state.favorites;
    const { startTime, endTime, spaceId, spaceBackupId } = localFavorites;
    const isValid = () => !!(startTime && endTime && spaceId && spaceBackupId);
    if (!isValid()) {
      this.setState({ message: 'favorites.allFieldsRequired' });
      return;
    }
    if (globalFavorites) {
      this.props.updateFavorites(globalFavorites.id, localFavorites);
    } else {
      this.props.createFavorites(localFavorites);
    }
    this.setState({ message: '' });
  };

  handleDeleteFavorites = () => {
    const { favorites, login } = this.props;
    if (!favorites.id) return;
    this.props.deleteFavorites(favorites.id);
    this.setState({ favorites: defaultFavorites(login) });
  };

  timeSetter = (e, fieldName) => {
    const { value } = e.target;
    this.setState(currentState => ({
      favorites: {
        ...currentState.favorites,
        [fieldName]: value ? `${value}:00.000000000` : '',
      },
    }));
  };

  dropdownOptionSetter = (option, fieldName) => {
    this.setState(currentState => ({
      favorites: {
        ...currentState.favorites,
        [fieldName]: option ? option.value : null,
      },
    }));
  };

  render() {
    const {
      favorites: { spaceId, spaceBackupId, startTime, endTime },
      message,
    } = this.state;
    const { translate, spaces, login } = this.props;
    const spacesOptions = spaces.map(space => ({
      value: space.id,
      name: space.name,
      label: space.name,
    }));
    return login ? (
      <div className="Favorites">
        <p className="Favorites__message">{message && translate(message)}</p>
        <div className="Favorites__field-wrapper">
          <p className="Favorites__field-label">{translate('favorites.preferredSpace')}</p>
          <VirtualizedSelect
            className="Favorites__field"
            placeholder={translate('favorites.placeholder')}
            options={spacesOptions}
            onChange={option => this.dropdownOptionSetter(option, 'spaceId')}
            value={spaceId}
          />
        </div>
        <div className="Favorites__field-wrapper">
          <p className="Favorites__field-label">{translate('favorites.secondPreferredSpace')}</p>
          <VirtualizedSelect
            className="Favorites__field"
            placeholder={translate('favorites.placeholder')}
            options={spacesOptions}
            onChange={option => this.dropdownOptionSetter(option, 'spaceBackupId')}
            value={spaceBackupId}
          />
        </div>
        <div className="Favorites__field-wrapper">
          <p className="Favorites__field-label">{translate('favorites.startTime')}</p>
          <input
            className="Favorites__input Favorites__field"
            type="time"
            value={startTime && startTime.match(/^(\d{2}:\d{2})/)[1]}
            onChange={e => this.timeSetter(e, 'startTime')}
          />
        </div>
        <div className="Favorites__field-wrapper">
          <p className="Favorites__field-label">{translate('favorites.endTime')}</p>
          <input
            className="Favorites__input Favorites__field"
            type="time"
            value={endTime && endTime.match(/^(\d{2}:\d{2})/)[1]}
            onChange={e => this.timeSetter(e, 'endTime')}
          />
        </div>
        <button
          className="Favorites__submit-button"
          type="button"
          onClick={this.handleSetFavorites}
        >
          {translate('favorites.submitFavorites')}
        </button>
        <button
          className="Favorites__submit-button"
          type="button"
          onClick={this.handleDeleteFavorites}
        >
          {translate('favorites.deleteFavorites')}
        </button>
      </div>
    ) : null;
  }
}

Favorites.propTypes = {
  updateFavorites: PropTypes.func.isRequired,
  createFavorites: PropTypes.func.isRequired,
  deleteFavorites: PropTypes.func.isRequired,
  fetchSpaces: PropTypes.func.isRequired,
  favorites: PropTypes.shape({
    id: PropTypes.number,
    employee: PropTypes.string,
    endTime: PropTypes.string,
    preferredStartPage: PropTypes.string,
    spaceBackupId: PropTypes.number,
    spaceId: PropTypes.number,
    startTime: PropTypes.string,
  }),
  login: PropTypes.string,
  chosenDate: PropTypes.string,
  translate: PropTypes.func,
  spaces: PropTypes.arrayOf(PropTypes.object),
};

Favorites.defaultProps = {
  translate: () => {},
  favorites: null,
  login: '',
  chosenDate: '',
  spaces: null,
};

const mapStateToProps = state => ({
  favorites: state.handleFavorites.favorites,
  spaces: state.handleSpaces.spaces,
  login: state.handleUser.user.login,
  chosenDate: state.handleDate.chosenDate,
});

const mapDispatchToProps = {
  fetchFavoritesByLogin,
  createFavorites,
  updateFavorites,
  deleteFavorites,
  fetchSpaces,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
