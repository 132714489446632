import * as actionTypes from '../constants/actionTypes';

const defaultState = {
  planList: [],
  plans: [],
  activePlanId: undefined,
};

const handlePlanActions = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.PLAN_LIST_GET_SUCCESS:
      return {
        ...state,
        planList: action.planList,
      };
    case actionTypes.PLAN_GET_SUCCESS:
      return {
        ...state,
        plans: [...state.plans, { id: action.id, plan: action.plan }],
      };
    case actionTypes.SET_ACTIVE_PLAN:
      return {
        ...state,
        activePlanId: action.planId,
      };
    default:
      return state;
  }
};

export default handlePlanActions;
