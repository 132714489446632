import * as actionTypes from '../constants/actionTypes';

const initialState = {
  original: null,
  message: null,
  status: null,
};

const handleError = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_ERROR:
      return {
        ...state,
        message: action.message,
        status: action.status,
        original: action.original,
      };
    case actionTypes.RESET_API_ERROR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default handleError;
