import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getBackendVersion } from '../../redux/actions';

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      frontendVersion: '',
    };
  }

  componentDidMount() {
    fetch('/version.json')
      .then(response => response.json())
      .then(data => {
        this.setState({ frontendVersion: `${data.tag} - ${data.shaShort}` });
      });
    !this.props.backendVersion.tag && this.props.getBackendVersion();
  }

  render() {
    const { tag, shaShort } = this.props.backendVersion;
    return (
      <p style={{ color: 'black', textAlign: 'center', marginTop: '30px' }}>
        AKRA GmbH&nbsp;
        {moment().format('YYYY')}
        <br />
        Frontend:
        {` ${this.state.frontendVersion}`}
        <br />
        Backend:
        {` ${tag} - ${shaShort}`}
      </p>
    );
  }
}

About.propTypes = {
  getBackendVersion: PropTypes.func,
  backendVersion: PropTypes.shape({
    tag: PropTypes.string,
    shaShort: PropTypes.string,
  }),
};

About.defaultProps = {
  getBackendVersion: () => {},
  backendVersion: {
    tag: '',
    shaShort: '',
  },
};

export default connect(
  state => ({
    backendVersion: state.handleVersion.backendVersion,
  }),
  { getBackendVersion }
)(About);
