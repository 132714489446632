import * as actionTypes from '../constants/actionTypes';

const initialState = { reservations: [] };

const handleReservations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESERVATIONS_GET_SUCCESS:
      return { ...state, reservations: action.reservations };
    case actionTypes.RESERVATION_DELETE_SUCCESS:
      return {
        ...state,
        reservations: state.reservations.filter(
          reservation => reservation.id !== action.reservationId
        ),
      };
    case actionTypes.RESERVATION_POST_SUCCESS:
      return {
        ...state,
        reservations: [...state.reservations, action.reservation],
      };
    case actionTypes.RESERVATION_SERIES_POST_SUCCESS:
      return {
        ...state,
        reservations: [...state.reservations, ...action.reservationSeries],
      };
    case actionTypes.RESERVATION_PUT_SUCCESS:
      return {
        ...state,
        reservations: state.reservations.map(reservation =>
          reservation.id === action.id
            ? { ...action.reservation, id: action.id, spaceName: reservation.spaceName }
            : reservation
        ),
      };
    default:
      return state;
  }
};

export default handleReservations;
