const setLogLevel = () => {
  switch (process.env.REACT_APP_LOG_LEVEL) {
    case 'debug':
      // print everything
      break;

    case 'log':
      console.debug = function() {};
      break;

    case 'info':
      console.debug = function() {};
      console.dir = function() {};
      console.log = function() {};
      break;

    case 'error':
      console.debug = function() {};
      console.dir = function() {};
      console.log = function() {};
      console.info = function() {};
      break;
    default:
      break;
  }
};

export default setLogLevel;
