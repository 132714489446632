import * as actionTypes from '../constants/actionTypes';

const initialState = { spaces: [] };

const handleSpaces = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SPACES_GET_SUCCESS:
      return { ...state, spaces: action.spaces };
    default:
      return state;
  }
};

export default handleSpaces;
