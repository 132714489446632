import { LOG_OUT, FETCH_TOKENS_SUCCESS } from '../constants/actionTypes';
import { getRefreshTokenFromStorage } from '../../auth/tokenStorage';

const emptyState = {
  accessToken: null,
  accessTokenExpires: null,
  refreshToken: null,
};

const initialState = {
  ...emptyState,
  refreshToken: getRefreshTokenFromStorage(),
};

const handleAuthorization = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOKENS_SUCCESS: {
      const { type, ...rest } = action;
      return {
        ...rest,
      };
    }
    case LOG_OUT:
      return {
        ...emptyState,
      };
    default:
      return state;
  }
};

export default handleAuthorization;
