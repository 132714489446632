import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { refreshAccessToken } from '../../redux/actions';
import { isBlank, isNotBlank } from '../../commons';
import Login from './Login';

class AuthorizedRoute extends Component {
  tokenExpirationTimeout = null;

  componentDidMount = () => {
    this.initTokenExpirationBasedOnOnlineStatus(this.props.isOnline);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.isOnline !== prevProps.isOnline) {
      this.initTokenExpirationBasedOnOnlineStatus(this.props.isOnline);
    }
  };

  componentWillUnmount() {
    this.clearTokenExpirationTimeout();
  }

  initTokenExpirationBasedOnOnlineStatus = isOnline => {
    if (isOnline) {
      if (isBlank(this.props.refreshToken)) {
        return;
      }

      this.initTokenRefreshing();
    } else {
      this.clearTokenExpirationTimeout();
    }
  };

  initTokenRefreshing = () => {
    if (!this.isAccessTokenValid()) {
      this.refreshAccessToken(true);
    } else {
      this.setTokenExpirationTimeout();
    }
  };

  isAccessTokenValid = () => {
    const { accessToken, accessTokenExpires } = this.props;
    return !!accessToken && !!accessTokenExpires && Date.now() < accessTokenExpires;
  };

  refreshAccessToken = withSpinner => {
    return this.props.refreshAccessToken(withSpinner).then(() => {
      this.setTokenExpirationTimeout();
    });
  };

  setTokenExpirationTimeout = () => {
    this.clearTokenExpirationTimeout();
    const expiresIn = this.props.accessTokenExpires - Date.now();
    if (expiresIn > 0) {
      this.tokenExpirationTimeout = setTimeout(() => this.refreshAccessToken(false), expiresIn);
    }
  };

  clearTokenExpirationTimeout = () => {
    clearTimeout(this.tokenExpirationTimeout);
  };

  renderAuthorizedComponent = props => {
    const { component: AuthorizedComponent, render, computedMatch, userLogin } = this.props;
    if (this.isAccessTokenValid() && isNotBlank(userLogin)) {
      return render ? render(computedMatch) : <AuthorizedComponent {...props} />;
    }
    return null;
  };

  render = () => {
    const { component: AuthorizedComponent, refreshToken, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          /* eslint-disable no-nested-ternary */
          isBlank(refreshToken) ? <Login /> : this.renderAuthorizedComponent(props)
        }
      />
    );
  };
}

AuthorizedRoute.propTypes = {
  render: PropTypes.func,
  component: PropTypes.node,
  computedMatch: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  isOnline: PropTypes.bool,
  accessToken: PropTypes.string,
  accessTokenExpires: PropTypes.number,
  refreshToken: PropTypes.string,
  refreshAccessToken: PropTypes.func.isRequired,
  userLogin: PropTypes.string,
};

AuthorizedRoute.defaultProps = {
  render: () => null,
  component: '',
  computedMatch: null,
  isOnline: true,
  accessToken: null,
  accessTokenExpires: null,
  refreshToken: null,
  userLogin: null,
};

export default connect(
  state => ({
    ...state.handleAuthorization,
    userLogin: state.handleUser.user.login,
    isOnline: state.handleApplication.isOnline,
  }),
  {
    refreshAccessToken,
  }
)(AuthorizedRoute);
