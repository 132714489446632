import Cookies from 'js-cookie';
import constants from '../constants';

export const getRefreshTokenFromStorage = () => {
  return Cookies.get(constants.REFRESH_TOKEN_COOKIE_KEY);
};

export const setRefreshTokenInStorage = refreshToken => {
  if (refreshToken) {
    Cookies.set(constants.REFRESH_TOKEN_COOKIE_KEY, refreshToken, {
      expires: constants.REFRESH_TOKEN_COOKIE_EXPIRES,
    });
  } else {
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_KEY);
  }
};
