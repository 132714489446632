import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { resetApiError } from '../../redux/actions';
import '../../css/ErrorMessage.css';

const ErrorMessage = ({ translate, status, message, ...props }) => (
  <Dialog
    fullWidth
    open={!!message}
    onClose={props.resetApiError}
    disableBackdropClick
    className="ErrorMessage"
  >
    <DialogTitle className="ErrorMessage-header" disableTypography>
      <DialogActions>
        <button type="button" className="ErrorMessage-closeButton" onClick={props.resetApiError}>
          &times;
        </button>
      </DialogActions>
      {translate('errors.error')}
    </DialogTitle>
    <DialogContent className="ErrorMessage-content">
      <DialogContentText className="ErrorMessage-message">
        {translate(`errors.${message}`)}
      </DialogContentText>
      <DialogContentText className="ErrorMessage-message">
        {status && translate('errors.status', { status })}
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

ErrorMessage.propTypes = {
  message: PropTypes.string,
  status: PropTypes.number,
  resetApiError: PropTypes.func,
  translate: PropTypes.func,
};

ErrorMessage.defaultProps = {
  message: '',
  status: undefined,
  resetApiError: () => {},
  translate: () => {},
};

export default connect(state => state.handleError, { resetApiError })(ErrorMessage);
