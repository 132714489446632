/**
 * @class SvgZoom
 * Component allows for zooming and moving of a wrapped SVG image (passed in as this.props.children).
 * Can be cofingured with 2 parameters:
 * @param {Number} maxZoom - maximum zoom ratio allowed
 * @param {Number} mouseZoomRatio - zoom ratio applied with each scroll of a mouse
 * Supported events:
 * - mouseWheel and 2 finger pinch for zooming
 * - mouseMove and touchMove for moving (only when SVG is zoomed in)
 * - doubleClick - resets SVG to its original size and position
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import * as Calc from '../../lib/zoomMoveCalculator';

let scrollablePartOnZoomZero;

class SvgZoom extends Component {
  constructor(props) {
    super(props);
    this.mouseZoomInRatio = props.mouseZoomRatio;
    this.mouseZoomOutRatio = 1 / props.mouseZoomRatio;
    this.svgElement = null;
    this.draggable = false;
    this.startPoint = { x: null, y: null };
    this.calculateMoveDeltaFromStart = null;
    this.calculatePinchZoomRatioFromStart = null;
    this.calculateCurrentTransformBoundaries = null;
    this.transformBoundaries = {
      zoom: { min: 1, max: props.maxZoom },
      x: { min: 0, max: 0 },
      y: { min: 0, max: 0 },
    };
    this.currentTransformValues = { x: 0, y: 0, zoom: 1 };
  }

  componentDidMount = () => {
    this.svgElement = document.querySelector('#SVGZoomManipulator > svg');
    const htmlElement = document.querySelector('html');
    const svgBoundingRect = this.svgElement.getBoundingClientRect();
    // Calculate how much of svg is not visible and allow user to scroll down
    if (htmlElement.offsetHeight > window.innerHeight && isMobile) {
      scrollablePartOnZoomZero = window.innerHeight - svgBoundingRect.top - svgBoundingRect.height;
    }
    // Partially apply the calculateTransformBoundaries to pre-load it with initial values
    this.calculateCurrentTransformBoundaries = Calc.calculateTransformBoundaries(
      svgBoundingRect,
      this.props.maxZoom
    );
    this.transformBoundaries.y.min = scrollablePartOnZoomZero || 0;
    // Touchmove added in a non-react way to add passive = false to enable preventDefault in Chrome
    this.svgElement.addEventListener('touchmove', this.onTouchMove, { passive: false });

    // Resize event is currently not supoorted in React
    this.svgElement.addEventListener('resize', this.resetSvg);
  };

  componentWillUnmount() {
    this.svgElement.removeEventListener('touchmove', this.onTouchMove, { passive: false });
    this.svgElement.removeEventListener('resize', this.resetSvg);
  }

  // Helper function calculating pinch center for touch events
  calculatePinchCenter = (originStartPoint, originEndPoint) => ({
    x: (originStartPoint.x + originEndPoint.x) / 2,
    y: (originStartPoint.y + originEndPoint.y) / 2,
  });

  // Helper curried function calculating delta values for move events
  calculateMoveDelta = startPoint => curentPoint => {
    const x = curentPoint.x - startPoint.x;
    const y = curentPoint.y - startPoint.y;
    this.startPoint.x = curentPoint.x;
    this.startPoint.y = curentPoint.y;
    return { x, y };
  };

  // Helper curried function calculating delta values for zooming with touch events
  calculatePinchZoomRatio = (originPinchLength, startPinchCenter) => currentPinchLength => {
    const zoomRatio = currentPinchLength / originPinchLength;
    return {
      zoomRatio,
      x: startPinchCenter.x,
      y: startPinchCenter.y,
    };
  };

  // Does the actual transformation of the SVG keeping the transform values within maximum boundaries
  transformSvg = newTranformValues => {
    this.currentTransformValues = Calc.keepTransformValuesInBoundaries(this.transformBoundaries)(
      newTranformValues
    );
    const { x, y, zoom } = this.currentTransformValues;
    this.svgElement.style.transform = `matrix(${zoom}, 0, 0, ${zoom}, ${x}, ${y})`;
  };

  // Moves the SVG
  moveSvg = clientPoint => {
    const delta = this.calculateMoveDeltaFromStart(clientPoint);
    const transformValues = Calc.calculateCurrentTransformValues(this.currentTransformValues, {
      ...delta,
      zoomRatio: 1,
    });
    this.transformSvg(transformValues);
  };

  // Zooms the SVG keeping the center point of the zoom in its original position in the viewport
  zoomSvg = (zoomRatio, clientPoint) => {
    // If zoom out of boudries than do nothing
    if (
      !Calc.isZoomInRange(
        zoomRatio,
        this.currentTransformValues.zoom,
        this.transformBoundaries.zoom
      )
    ) {
      return;
    }
    // Correct partial zoom ratio to keep the resulting zoom within boundaries
    const zoomRatioInBoundaries = Calc.calculateZoomRatioInBoundaries(
      this.transformBoundaries.zoom,
      this.currentTransformValues.zoom,
      zoomRatio
    );
    // Update move boundaries after zoom (zoom changes values of boundaries)
    this.transformBoundaries = this.calculateCurrentTransformBoundaries(
      zoomRatioInBoundaries * this.currentTransformValues.zoom
    );
    // Calculate move delta to correct position after zoom to keep the center point of the zoom in its original position
    const delta = Calc.calculateMoveAfterZoom(
      zoomRatioInBoundaries,
      this.svgElement.getBoundingClientRect(),
      clientPoint
    );
    // Calculate the final transformation values
    const transformValues = Calc.calculateCurrentTransformValues(
      this.currentTransformValues,
      delta
    );
    this.transformSvg(transformValues);
  };

  // Resets SVG to its original values (100% zoom)
  resetSvg = () => {
    this.currentTransformValues = { x: 0, y: 0, zoom: 1 };
    this.transformBoundaries = {
      zoom: { min: 1, max: this.props.maxZoom },
      x: { min: 0, max: 0 },
      y: { min: scrollablePartOnZoomZero, max: 0 },
    };
    this.svgElement.style.transform = `matrix(1, 0, 0, 1, 0, 0)`;
  };

  // Event handlers start here
  onWheel = evt => {
    const zoomRatio = evt.deltaY < 0 ? this.mouseZoomInRatio : this.mouseZoomOutRatio;
    this.zoomSvg(zoomRatio, { x: evt.clientX, y: evt.clientY });
  };

  onMouseMove = evt => {
    if (this.isDraggable) {
      this.moveSvg({ x: evt.clientX, y: evt.clientY });
    }
  };

  onMouseDown = evt => {
    this.isDraggable = true;

    // Partially apply the calculateMoveDelta function to keep track of the starting point
    this.calculateMoveDeltaFromStart = this.calculateMoveDelta({ x: evt.clientX, y: evt.clientY });
  };

  cancelMove = evt => {
    this.isDraggable = false;
  };

  onTouchMove = evt => {
    if (evt.cancelable) {
      evt.preventDefault();
    }
    const ts = evt.touches;
    // if number of touches = 1 it is a move event, if more it's a pinch
    if (ts.length === 1) {
      if (this.isDraggable) {
        this.moveSvg({ x: ts[0].clientX, y: ts[0].clientY });
      }
    } else {
      const currentStartPoint = { x: ts[0].clientX, y: ts[0].clientY };
      const currentEndPoint = { x: ts[1].clientX, y: ts[1].clientY };
      const currentPinchLength = this.calculatePincLength(currentStartPoint, currentEndPoint);
      const pinchValues = this.calculatePinchZoomRatioFromStart(currentPinchLength);
      this.zoomSvg(pinchValues.zoomRatio, { x: pinchValues.x, y: pinchValues.y });
    }
  };

  onTouchStart = evt => {
    const ts = evt.touches;

    // if number of touches = 1 it is a move event, if more it's a pinch
    if (ts.length === 1) {
      this.isDraggable = true;

      // Partially apply the calculateMoveDelta function to keep track of the starting point
      this.calculateMoveDeltaFromStart = this.calculateMoveDelta({
        x: ts[0].clientX,
        y: ts[0].clientY,
      });
    } else {
      const originStartPoint = { x: ts[0].clientX, y: ts[0].clientY };
      const originEndPoint = { x: ts[1].clientX, y: ts[1].clientY };
      const originPinchLength = this.calculatePincLength(originStartPoint, originEndPoint);
      const originPinchCenter = this.calculatePinchCenter(originStartPoint, originEndPoint);

      // Partially apply the calculatePinchZoomRatio function to keep track of the starting pinch values
      this.calculatePinchZoomRatioFromStart = this.calculatePinchZoomRatio(
        originPinchLength,
        originPinchCenter
      );
    }
  };

  // Helper function for calculating pinch length for touch events
  calculatePincLength = (originStartPoint, originEndPoint) =>
    Math.hypot(
      Math.abs(originStartPoint.x - originEndPoint.x),
      Math.abs(originStartPoint.y - originEndPoint.y)
    );

  render() {
    return (
      // position and padding-bottom styles added for proper IE 11 rendering
      <div
        role="presentation"
        id="SVGZoomManipulator"
        style={{
          overflow: 'hidden',
          position: 'relative',
          paddingBottom: '166%',
        }}
        onWheel={this.onWheel}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.cancelMove}
        onMouseUp={this.cancelMove}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.cancelMove}
        onTouchCancel={this.cancelMove}
        onDoubleClick={this.resetSvg}
      >
        {this.props.children}
      </div>
    );
  }
}

SvgZoom.propTypes = {
  mouseZoomRatio: PropTypes.number,
  maxZoom: PropTypes.number,
  children: PropTypes.node.isRequired,
};

SvgZoom.defaultProps = {
  mouseZoomRatio: 1.5,
  maxZoom: 2,
};

export default SvgZoom;
