import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Space from './Space';
import { fetchSpaces, addNewReservation } from '../../redux/actions';
import '../../css/SpacesContainer.css';

class SpacesContainer extends Component {
  componentDidMount = () => {
    this.props.fetchSpaces(this.props.date);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.date !== prevProps.date) {
      this.props.fetchSpaces(this.props.date);
    }
  };

  handleClick = (spaceId, spaceName) => {
    // eslint-disable-next-line no-shadow
    const { history, addNewReservation } = this.props;
    addNewReservation(spaceId, spaceName);
    history.push('/reservation', { spaceId, spaceName });
  };

  render() {
    const { spaces, translate } = this.props;
    return (
      <div className="SpacesContainer">
        {spaces.map(space => (
          <Space
            key={space.id}
            space={space}
            onSpaceClicked={this.handleClick}
            translate={translate}
          />
        ))}
      </div>
    );
  }
}

SpacesContainer.propTypes = {
  spaces: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      planId: PropTypes.number,
      name: PropTypes.string,
      coordinates: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      state: PropTypes.string,
      assigned: PropTypes.bool,
    })
  ),
  date: PropTypes.string,
  fetchSpaces: PropTypes.func.isRequired,
  addNewReservation: PropTypes.func.isRequired,
  translate: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
};

SpacesContainer.defaultProps = {
  spaces: [],
  translate: () => {},
  date: '',
};

const mapStateToProps = state => ({
  spaces: state.handleSpaces.spaces,
});

export default connect(mapStateToProps, { fetchSpaces, addNewReservation })(SpacesContainer);
