import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchUserProfileByLogin, searchReservations } from '../../lib/RestClient';
import {
  fetchExistingSpaceReservationsSuccess,
  handleApiError,
  deleteReservation,
  editReservation,
} from '../../redux/actions';
import constants from '../../constants';
import '../../css/ExistingSpaceReservations.css';
import editIcon from '../../image/edit.svg';
import trashIcon from '../../image/trash.svg';

class ExistingSpaceReservations extends Component {
  componentDidMount = async () => {
    await this.getCurrentSpaceReservations(this.props.date);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.date !== prevProps.date || this.props.id !== prevProps.id) {
      this.getCurrentSpaceReservations(this.props.date);
    }
  };

  getCurrentSpaceReservations = async date => {
    const convertedDate =
      date && moment(date, constants.DATE_FORMAT_FRONTEND).format(constants.DATE_FORMAT_BACKEND);
    const filters = {
      spaceIds: [this.props.spaceId],
      employees: [],
      startDate: convertedDate,
      endDate: convertedDate,
    };
    await searchReservations(filters)
      .then(reservations => {
        this.mapReservationsData(reservations.data);
      })
      .catch(err => this.props.handleApiError(err));
  };

  mapReservationsData = reservationsData => {
    if (!reservationsData) {
      return;
    }
    const otherReservations = reservationsData.filter(
      reservation => reservation.id !== this.props.id
    );
    /* eslint-disable-next-line */
    const reservationsPromises = otherReservations.map(reservation => {
      const { employee, updatedBy, guestName, startTime, endTime } = reservation;
      let employeeDetails;
      let reservationOwner;
      // If user made reservation for him/herself, reservationOwner equals null
      return (async () => {
        try {
          employeeDetails = await fetchUserProfileByLogin(employee);
          reservationOwner =
            (employee !== updatedBy || guestName) && (await fetchUserProfileByLogin(updatedBy));
        } catch (error) {
          this.props.handleApiError(error);
          /* eslint-disable consistent-return */
          return;
        }
        return {
          name: guestName || `${employeeDetails.data.firstName} ${employeeDetails.data.lastName}`,
          startTime: moment(startTime, constants.TIME_FORMAT).format(constants.TIME_FORMAT_SHORT),
          endTime: moment(endTime, constants.TIME_FORMAT).format(constants.TIME_FORMAT_SHORT),
          reservationOwner: reservationOwner
            ? `${reservationOwner.data.firstName} ${reservationOwner.data.lastName}`
            : null,
          restData: reservation,
        };
      })();
    });

    Promise.all(reservationsPromises)
      .then(responses => this.props.fetchExistingSpaceReservationsSuccess(responses))
      .catch(err => this.props.handleApiError(err));
  };

  onDeleteClicked = id => {
    this.props.deleteReservation(id);
    const filtered = this.props.existingSpaceReservations.filter(
      reservation => id !== reservation.restData.id
    );
    this.props.fetchExistingSpaceReservationsSuccess(filtered);
  };

  editReservation = reservation => {
    this.props.editReservation(reservation);
    this.props.history.push(`/reservation/${reservation.id}`);
  };

  isOwnReservation = reservation => {
    const { employee, createdBy } = reservation.restData;
    const { userLogin } = this.props;
    return employee === userLogin || createdBy === userLogin;
  };

  render() {
    const { existingSpaceReservations, isAdmin, translate } = this.props;
    return (
      <div className="ExistingSpaceReservations">
        {existingSpaceReservations.map(spaceReservation => (
          <div
            className="ExistingSpaceReservations-reservationEntry"
            key={spaceReservation.restData.id}
          >
            <span>{`${spaceReservation.startTime} - ${spaceReservation.endTime}`}</span>
            <span
              className={classnames('ExistingSpaceReservations-name', {
                'ExistingSpaceReservations-name--small': spaceReservation.reservationOwner,
              })}
            >
              {spaceReservation.name}
              {spaceReservation.reservationOwner && (
                <>
                  <br />
                  {`${translate('by')} ${spaceReservation.reservationOwner}`}
                </>
              )}
            </span>
            {/* Show delete and edit buttons when user has admin rights or it's user's own reservation */}
            {(isAdmin || this.isOwnReservation(spaceReservation)) && (
              <div className="ExistingSpaceReservations-buttonsContainer">
                <button
                  className="ExistingSpaceReservations-button"
                  type="button"
                  onClick={() => this.editReservation(spaceReservation.restData)}
                >
                  <img
                    className="ExistingSpaceReservations-buttonImage"
                    src={editIcon}
                    alt="Edit"
                  />
                </button>
                <button
                  className="ExistingSpaceReservations-button"
                  type="button"
                  onClick={() => this.onDeleteClicked(spaceReservation.restData.id)}
                >
                  <img
                    className="ExistingSpaceReservations-buttonImage"
                    src={trashIcon}
                    alt="Delete"
                  />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

ExistingSpaceReservations.propTypes = {
  spaceId: PropTypes.number,
  fetchExistingSpaceReservationsSuccess: PropTypes.func.isRequired,
  handleApiError: PropTypes.func.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  editReservation: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  existingSpaceReservations: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.number,
  isAdmin: PropTypes.bool,
  userLogin: PropTypes.string,
  translate: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  history: PropTypes.object.isRequired,
};

ExistingSpaceReservations.defaultProps = {
  spaceId: null,
  existingSpaceReservations: [],
  id: null,
  isAdmin: false,
  userLogin: '',
  translate: () => {},
};

export default connect(state => state.handleSingleReservation, {
  fetchExistingSpaceReservationsSuccess,
  handleApiError,
  deleteReservation,
  editReservation,
})(ExistingSpaceReservations);
