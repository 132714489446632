import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../css/Button.css';

const Button = ({ tabButton, long, active, handleClick, children, ...rest }) => (
  <button
    type="button"
    className={classnames('Button', {
      'Button--active': active,
      'Button--long': long,
      'Button--tab': tabButton,
    })}
    onClick={handleClick}
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  /** Simply the type of the root button element  */
  type: PropTypes.string,
  /** Longer version */
  long: PropTypes.bool,
  /** Determines if the tabButton has border-bottom line and if the others have red background  */
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  /** Button used in navbar */
  tabButton: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  long: false,
  active: false,
  tabButton: false,
  handleClick: () => {},
};

export default Button;
