import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import Cookies from 'js-cookie';
import moment from 'moment';
import { environment, languageCookieName } from '../commons';

import constants from '../constants';

const chosenLang = Cookies.get(languageCookieName) || 'en-GB';
const { months, weekdaysMin } = constants.MOMENT_LOCALE_DATA[chosenLang];
moment.updateLocale(chosenLang, { months, weekdaysMin });

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    debug: environment === 'development',
    lng: chosenLang,
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
