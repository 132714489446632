import * as RestClient from '../../lib/RestClient';
import * as actionTypes from '../constants/actionTypes';
import { requestWrapper } from './helperActions';

export const fetchSpaces = forDate => dispatch => {
  return requestWrapper(
    RestClient.fetchSpaces(forDate).then(response => {
      dispatch(fetchSpacesSuccess(response.data));
      return response.data;
    })
  )(dispatch);
};

export const fetchSpacesSuccess = spaces => ({
  type: actionTypes.SPACES_GET_SUCCESS,
  spaces,
});
