import {
  API_ERROR,
  FETCH_TOKENS_SUCCESS,
  FETCH_TOKEN_INFO_SUCCESS,
} from '../constants/actionTypes';
import { setRefreshTokenInStorage } from '../../auth/tokenStorage';
import constants from '../../constants';

const authorizationMiddleware = store => next => action => {
  switch (action.type) {
    case FETCH_TOKENS_SUCCESS:
      setRefreshTokenInStorage(action.refreshToken);
      break;
    case API_ERROR:
      // prevent from displaying api error when application in offline mode
      if (!store.getState().handleApplication.isOnline) {
        return null;
      }
      break;
    case FETCH_TOKEN_INFO_SUCCESS: {
      // @Maciej Studniarz - this is workaround for org team. When
      // user preferences are introduced, handling of default pathnames will change
      const isAdmin = action.token.scope.indexOf('spaces:admin') > -1;
      const pathname = isAdmin ? constants.PATH.PLAN_MAP : constants.PATH.SPACES;
      window.localStorage.setItem(constants.SPACES_REDIRECT_PATH_PARAM_NAME, pathname);
      break;
    }
    default:
      break;
  }

  return next(action);
};

export default authorizationMiddleware;
