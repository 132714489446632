import React from 'react';
import PropTypes from 'prop-types';

const getSvgElement = plan => plan.childNodes[0];

const getElementAttributes = element => {
  const elementAttributes = {};
  Object.keys(element.attributes).forEach(key => {
    const attr = element.attributes[key];
    elementAttributes[attr.name] = attr.value;
  });
  return elementAttributes;
};

const PlanSvg = ({ svg, children }) => {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const plan = parser.parseFromString(svg, 'image/svg+xml');
  const svgElement = getSvgElement(plan);
  const svgContent = serializer.serializeToString(svgElement);
  const svgAttributes = getElementAttributes(svgElement);
  return (
    // svg styles added for proper IE 11 rendering
    <svg {...svgAttributes} style={{ width: '100%', position: 'absolute' }}>
      <g dangerouslySetInnerHTML={{ __html: svgContent }} />
      {children}
    </svg>
  );
};

PlanSvg.propTypes = {
  /** 'Parent' svg element, generating <g> tag containing fetched garage map. */
  svg: PropTypes.string,
  /** children here represent fetched PlanSpace components, distributed on the map according to their coordinates. */
  children: PropTypes.node,
};

PlanSvg.defaultProps = {
  svg: null,
  children: null,
};

export default PlanSvg;
