import React from 'react';
import PropTypes from 'prop-types';
import '../../css/NotFound.css';

const NotFound = ({ translate }) => (
  <div className="NotFound">
    <p>{translate('notFound')}</p>
  </div>
);

NotFound.propTypes = {
  translate: PropTypes.func,
};

NotFound.defaultProps = {
  translate: () => {},
};

export default NotFound;
