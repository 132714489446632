import * as actionTypes from '../constants/actionTypes';

const initialState = {
  showGlobalSpinner: false,
  isOnline: true,
};

let actionsInProgress = [];

const handleApplication = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_GLOBAL_SPINNER:
      if (action.visible) {
        actionsInProgress.push(action.actionId);
      } else {
        actionsInProgress = actionsInProgress.filter(
          currentAction => currentAction !== action.actionId
        );
      }
      return { ...state, showGlobalSpinner: actionsInProgress.length > 0 };
    case actionTypes.CHANGE_ONLINE_STATUS:
      return {
        ...state,
        isOnline: action.isOnline,
      };
    default:
      return state;
  }
};

export default handleApplication;
