import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../shared/Button';
import '../../css/Login.css';
import akraLogoBlackRed from '../../image/akra_logo_black_red.svg';
import authService from '../../auth/authService';

const handleSubmit = pathname => event => {
  event.preventDefault();
  authService.authorize();
};

const Login = ({ location, t }) => (
  <div className="Login">
    <img src={akraLogoBlackRed} alt="AKRA GmbH" className="Login-akraLogo" />
    <p className="Login-spacesTitle">SPACES</p>
    <form className="form-signin" onSubmit={handleSubmit(location.pathname)}>
      <Button type="submit" long active>
        {t('signIn')}
      </Button>
    </form>
  </div>
);

Login.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func,
};

Login.defaultProps = {
  t: () => {},
};

export default withRouter(withTranslation()(Login));
